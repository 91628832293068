import { useQuery } from '@tanstack/react-query'

interface FetchOptions {
  enabled?: boolean
  refetchOnWindowFocus?: boolean
  staleTime?: number // Substitui o antigo `cacheTime`
  retry?: number
}

export function useFetchData<T>(
  fetchFn: () => Promise<T>,
  options?: FetchOptions,
) {
  return useQuery<T, Error>({
    queryKey: [fetchFn], // A chave pode ser a própria função de fetch
    queryFn: fetchFn, // Passamos a função fetch como parâmetro
    enabled: options?.enabled ?? true,
    refetchOnWindowFocus: options?.refetchOnWindowFocus ?? false,
    staleTime: options?.staleTime ?? 0, // Tempo antes de marcar como "stale"
    retry: options?.retry ?? 3, // Número de tentativas em caso de erro
  })
}
