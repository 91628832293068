import { Avatar, Box, Link, Stack, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import { EventCard, Loading } from 'components'
import { constants } from 'config'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import 'react-multi-carousel/lib/styles.css'
import { Link as RouterLink } from 'react-router-dom'
import { apiV1 } from 'services'
import { IEvento } from 'types/evento'
import { IPromoter } from 'types/promoter'
import { functionWithLoading } from 'utils'
import EventPage from './EventPage'

type ProducerEventsProps = {
  producerId: number
}

const ProducerEvents = ({ producerId }: ProducerEventsProps) => {
  const [producer, setProducer] = React.useState<IPromoter>()
  const [events, setEvents] = React.useState<IEvento[]>([])
  const [loadingEvents, setLoadingEvents] = React.useState(true)

  React.useEffect(() => {
    functionWithLoading(
      apiV1.eventService
        .getProducerEvents(producerId.toString())
        .then(({ data }) => {
          setProducer(data.producer)
          setEvents(data.events)
        }),
      setLoadingEvents,
    )
  }, [producerId])

  if (loadingEvents || !producer) return <Loading />

  return (
    <>
      <Helmet>
        <title>{producer.razao_social} - Zé do Ingresso</title>
      </Helmet>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            sx={{
              backgroundImage: `url(${constants.URL_FTP + producer.capa})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
            p={2}
            py={4}
          >
            <Stack
              alignItems="center"
              direction="row"
              gap={4}
              bgcolor="grey.300"
              justifyContent="left"
              sx={{ borderRadius: 500, maxWidth: 500 }}
            >
              <Avatar
                alt={producer.razao_social}
                src={constants.URL_FTP + producer.imagem}
                sx={{
                  bgcolor: 'primary',
                  width: 120,
                  height: 120,
                }}
                variant="circular"
              />

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <Typography variant="h5" sx={{ fontWeight: '900' }}>
                  {producer.razao_social}
                </Typography>
                <Typography variant="caption" component="p">
                  {String(producer.email).toLowerCase()}
                </Typography>
                <Typography variant="caption" component="p">
                  {producer.telefone}
                </Typography>
                <Typography variant="caption" component="p">
                  {producer.endereco}
                </Typography>
              </Box>
            </Stack>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2} p={2}>
        {events.length === 1 ? (
          <Grid item xs={12}>
            <EventPage id={events[0].id} />
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Próximos eventos
              </Typography>
            </Grid>

            {events.map((event) => (
              <Grid item key={'event_' + event.id} xs={12} sm={6} md={4}>
                <Link
                  component={RouterLink}
                  to={`/evento/${event.id}/${event.id_url}`}
                  underline="none"
                >
                  <EventCard event={event} />
                </Link>
              </Grid>
            ))}
          </>
        )}
      </Grid>
    </>
  )
}

export default ProducerEvents
