import type { GeneralEventReportType } from 'pages/promoter/GeneralReport'
import clientV2 from 'services/api/v2'

export type GeneralReportType = {
  report: {
    quantityTicketsApproved: number
    totalSales: number
    salesChannel: { name: string; quantity: number; total: number }[]
    paymentMethods: { name: string; quantity: number; total: number }[]
    sectors: { name: string; quantity: number; total: number }[]
    batches: { name: string; quantity: number; value: number; total: number }[]
    staff: { id: string; name: string; quantity: number; total: number }[]
  }
}

export async function fetchGeneralReport(
  eventId: string,
  body: GeneralEventReportType,
) {
  const response = await clientV2.post<GeneralReportType>(
    `/producer/events/${eventId}/reports/general`,
    body,
  )
  return response.data
}
