import clientAdmin from './client'

const eventImage = (data: FormData) => {
  return clientAdmin.post<string>(`upload/event`, data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

const batchImage = (data: FormData) => {
  return clientAdmin.post(`upload/batches`, data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

const importOrdersFromCsv = (eventId: number | string, data: FormData) => {
  return clientAdmin.post<string>(`upload/events/${eventId}/orders`, data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

const customEventLogoPOS = (eventId: string, data: FormData) => {
  return clientAdmin.post<{ path: string }>(
    `events/${eventId}/customize-logo`,
    data,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
    },
  )
}

const uploadService = {
  eventImage,
  batchImage,
  importOrdersFromCsv,
  customEventLogoPOS,
}

export default uploadService
