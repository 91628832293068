import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import {
  Alert,
  AlertProps,
  Box,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Skeleton,
} from '@mui/material'
import { TicketCard } from 'components'
import CancelOrderButton from 'components/CancelOrderButton'
import { Typography } from 'components/Typography'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { apiV1 } from 'services'
import { IPedido } from 'types/pedido'
import { functionWithLoading } from 'utils'
import { moneyFormatter, normalizeDateTime } from 'utils/formatter'

const OrderDetailsPage: React.FC = () => {
  const { orderId } = useParams()
  const [loading, setLoading] = useState(true)
  const [order, setOrder] = useState<IPedido>()

  const load = useCallback(() => {
    if (orderId)
      functionWithLoading(
        apiV1.orderService
          .getOrder(orderId ?? '')
          .then((res) => setOrder(res.data)),
        setLoading,
      )
  }, [orderId])

  useEffect(() => {
    load()
  }, [load])

  if (loading)
    return (
      <Container maxWidth="md">
        <Skeleton height={400} />
      </Container>
    )

  if (!order) {
    return (
      <Container maxWidth="md" component={Paper} sx={{ marginTop: 2, p: 2 }}>
        <Typography variant="h6">Ops! 😕</Typography>
        <Typography gutterBottom>
          <strong>Não foi possível acessar o link deste pedido. </strong>
          Por favor, tente novamente.
        </Typography>
        <Typography gutterBottom>
          📌 Você também pode acessar seus pedidos diretamente em{' '}
          <Link to={`/usuario/pedidos`}>Meus Pedidos</Link> e verificar se o
          ingresso está disponível na sua conta.
        </Typography>
        <Typography gutterBottom>
          ⚠️ <strong>Importante: </strong>Certifique-se de que está logado com o
          mesmo usuário que recebeu o e-mail do convite.
        </Typography>
        <Typography gutterBottom>
          Se o problema persistir, entre em contato com{' '}
          <Link
            to={`https://api.whatsapp.com/send?phone=5517996147824&text=Ol%C3%A1,%20vim%20atrav%C3%A9s%20do%20site%20(contato)`}
            target="_blank"
          >
            nosso suporte
          </Link>
          . Estamos à disposição para te ajudar! 🎟️😊
        </Typography>
      </Container>
    )
  }

  let statusColor = 'black'
  let renderTickets = false
  const alertProps: AlertProps = { severity: 'info' }

  switch (order.cod_status) {
    case 3:
    case 4:
    case 100:
    case 101:
    case 404:
      statusColor = 'green'
      renderTickets = true
      alertProps.severity = 'success'
      break
    case 107:
    case 7:
      statusColor = 'red'
      alertProps.severity = 'error'
      break
    case 6:
      statusColor = 'orange'
      alertProps.severity = 'warning'
      break
    default:
      break
  }

  return (
    <Container maxWidth="lg">
      <Grid
        container
        component={Paper}
        sx={{ my: 2, pr: 2, pb: 2 }}
        spacing={2}
      >
        <Grid item xs={12} sm={8}>
          <Typography variant="h6">COMPRADOR</Typography>
          <Typography variant="subtitle2">
            {[order.usuario.nome, order.usuario.sobrenome].join(' ')}
          </Typography>
          <Typography variant="subtitle2">{order.usuario.email}</Typography>

          <Typography variant="h6" sx={{ mt: 2 }}>
            EVENTO
          </Typography>
          <Typography variant="h6">
            <Link to={`/evento/${order.evento.id}/${order.evento.id_url}`}>
              {order.evento.titulo}
            </Link>
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <CalendarMonthIcon />
            {moment(order.evento.data).add(3, 'hours').format('LLLL')}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <LocationOnIcon />{' '}
            {order.evento?.local?.nome ??
              `${order.evento.local_evento} - ${order.evento.brasil_cidade?.cidade} - ${order.evento.brasil_cidade?.uf}`}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Alert {...alertProps} sx={{ justifyContent: 'center' }} icon={false}>
            <Typography variant="subtitle2" textAlign="center">
              Pedido
            </Typography>
            <Typography variant="h6" textAlign="center">
              {order.codigo}
            </Typography>
            <Typography variant="subtitle2" textAlign="center">
              {normalizeDateTime(order.data).format('L HH:MM:SS')}
            </Typography>
            <Typography
              variant="h6"
              textAlign="center"
              sx={{ color: statusColor }}
            >
              {order.status}
              {order.cod_status === 108 && (
                <Typography variant="body2" fontSize={12} textAlign="justify">
                  O Zé do Ingresso não se responsabiliza por ingressos comprados
                  ou transferidos de terceiros, pois os mesmos podem ser
                  cancelados caso o comprador original não cumpra os termos do
                  site ou dentre outras situações. Não haverá qualquer reposição
                  do ingresso, devendo o consumidor, caso queira, efetuar outra
                  compra em pontos oficiais.
                </Typography>
              )}
            </Typography>
            {![6, 13].includes(order.cod_status) && (
              <Typography variant="h6" textAlign="center">
                {moneyFormatter.format(
                  Number(order.valor_pedido) +
                    Number(order.taxa_processamento ?? 0),
                )}
              </Typography>
            )}
            {order.isCancelable && <CancelOrderButton orderId={order.codigo} />}
          </Alert>
        </Grid>

        {renderTickets ? (
          <Grid item xs={12}>
            <Typography variant="h6">MEUS INGRESSOS</Typography>
            <Grid container spacing={2}>
              {order.pedido_item?.map((item) => (
                <Grid item xs={12} sm={6} md={4} key={item.id}>
                  <TicketCard item={item} event={order.evento} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              <List disablePadding>
                {order.pedido_item?.map((item, index) => (
                  <ListItem key={`${item.id}_${index}`} sx={{ py: 1, px: 0 }}>
                    <ListItemText
                      primary={item.ingresso.titulo}
                      secondary={`taxa de serviço`}
                    />
                    <ListItemText
                      primary={moneyFormatter.format(item.valor_ingresso)}
                      secondary={moneyFormatter.format(item.valor_taxa)}
                      sx={{ textAlign: 'right' }}
                    />
                  </ListItem>
                ))}
                {order.taxa_processamento && (
                  <ListItem sx={{ py: 1, px: 0 }}>
                    <ListItemText primary="taxa processamento" />
                    <ListItemText
                      primary={moneyFormatter.format(order.taxa_processamento)}
                      sx={{ textAlign: 'right' }}
                    />
                  </ListItem>
                )}
                <ListItem sx={{ py: 1, px: 0 }}>
                  <ListItemText primary="Total" />
                  <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                    {moneyFormatter.format(
                      (order.pedido_item?.reduce(
                        (a, b) => Number(a) + Number(b.valor_total),
                        0,
                      ) ?? 0) + Number(order.taxa_processamento ?? 0),
                    )}
                  </Typography>
                </ListItem>
                {[6, 13].includes(order.cod_status) && (
                  <Box
                    alignItems="center"
                    display="flex"
                    justifyContent="space-between"
                    borderTop={1}
                  >
                    <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                      Reembolso: [
                      {moment(order.data_status).format('DD/MM/YYYY HH:mm')}]
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{ fontWeight: 700 }}
                      color="red"
                    >
                      {moneyFormatter.format(order.valor_ingressos)}
                    </Typography>
                  </Box>
                )}
              </List>
            </Grid>
          </>
        )}
      </Grid>
    </Container>
  )
}

export default OrderDetailsPage
