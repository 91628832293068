import { IPromoter } from 'types/promoter'
import clientProducer from './clientProducer'

const getAll = () => {
  return clientProducer.get<IPromoter[]>(`producers`)
}

const get = (producerId = '') => {
  return clientProducer.get<IPromoter>(`producers/${producerId}`)
}

type createData = {
  razao_social: string
  cnpj?: string
  email: string
  telefone: string
}
const create = (props: createData) => {
  return clientProducer.post(`producers`, { ...props })
}

type updateData = {
  razao_social: string
  cnpj?: string
  email: string
  telefone: string
  capa?: string
  imagem?: string
}
const update = (producerId = '', data: updateData) => {
  return clientProducer.patch(`producers/${producerId}`, { ...data })
}

const promoterService = {
  getAll,
  get,
  update,
  create,
}

export default promoterService
