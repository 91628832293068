import { Container, Paper } from '@mui/material'
import React from 'react'

const DoubsPage: React.FC = () => {
  return (
    <Container sx={{ padding: 2, marginY: 2 }} component={Paper}>
      <h1>Como funciona a compra de ingresso pelo site Zé do Ingresso?</h1>

      <h5>
        <em>Qual é o passo a passo?</em>
      </h5>

      <p>Simples, vamos ao passo a passo:</p>

      <ol>
        <li>
          Realize o <a href="/signup">cadastro</a> no site utilizando seus
          dados,
        </li>
        <li>localize o evento que deseja comprar,</li>
        <li>
          seleciona a quantidade, aperte o botão COMPRAR e aguarde a próxima
          tela,
        </li>
        <li>
          agora confira seu pedido e aperte o botão FINALIZAR PAGAMENTO, você
          será direcionada ao Mercado Pago,
        </li>
        <li>
          secione a forma de pagamento (cartão de crédito, catão débito CAIXA
          VIRTUAL, PIX, PayPal e boleto),
        </li>
        <li>
          insira as informações de pagamento solicitadas e aperte os botão
          PAGAR,
        </li>
        <li>
          aguarde a aprovação que será enviada via e-mail, juntamente com seus
          ingressos,
        </li>
        <li>
          ou encontre seus ingressos acessando o menu
          <a href="/usuario/pedidos">Meus Ingressos</a>
        </li>
      </ol>

      <p>
        e pronto agora é so apresentar o ingresso na portaria e curtir o role!
        <br />
        <br />
        Obs. Não é necessário imprimir o ingresso, basta apresentar no celular
        na portaria do evento.
      </p>

      <h1>O ingresso está em meu nome, outra pessoa pode utilizar?</h1>

      <h5>
        <em>
          Como trocar o nome do participante?
          <br />
          Meu amigo(a) quem irá usar o ingresso, como colocar o nome dele?
        </em>
      </h5>

      <p>
        Por padrão os ingressos são gerados em nome do comprador. No entanto,
        caso quem for utilizar o ingresso seja outra pessoa, basta acessar o
        pedido do ingresso em <a href="/usuario/pedidos">Meus Ingressos</a>
        editar o nome e CPF da pessoa que irá utilizá-lo. Em seguida baixe o
        ingresso no botão BAIXAR INGRESSO PDF e encaminhe para o participante.
      </p>

      <p>
        Não é necessário imprimir o ingresso, basta apresentá-lo no celular na
        portaria do evento.
        <br />
        Lembrando que, se você estiver vendendo ou comprando um ingresso de
        alguém desconhecido, por segurança, será necessário transferir a
        titularidade, realizando o procedimento abaixo.
      </p>

      <h1>Como efetuar a troca de titularidade do meu ingresso?</h1>

      <h5>
        <em>
          Vou vender meu ingresso para outra pessoa, como proceder?
          <br />
          Estou comprando meu ingresso de um desconhecido, qual é o
          procedimento?
        </em>
      </h5>

      <p>
        Se você estiver vendendo ou comprando um ingresso de alguém
        desconhecido, por segurança ser necessário transferir a titularidade
        através do botão Transferir Ingresso encontrado logo abaixo do QrCode do
        mesmo. Após realizar a transferência, o novo usuário receber um email
        com a confirmação e também poder visualiza-lo em{' '}
        <a href="/usuario/pedidos">Meus ingressos</a>.
      </p>

      <h1>Como efetuar o cancelamento e reembolso de um pedido?</h1>

      <h5>
        <em>Não irei mais ao evento, como solicitar o reembolso?</em>
        <br />
        <em>Quando tenho direito a estorno da compra?</em>
      </h5>
      <p>
        Conforme previsto no artigo 49, do Código de Defesa do Consumidor,
        eventual pedido de cancelamento da compra dever ser feito no prazo de
        até 7 dias da data da compra. Após esse prazo, ou no caso do pedido de
        cancelamento ser realizado nas 48 horas anteriores ao evento, a opção
        CANCELAR PEDIDO não estar disponível.
        <br />
        Se o seu pedido esta dentro dos prazos mencionados, basta acessar o
        pedido e clicar no botão CANCELAR PEDIDO
        <br />
        <a href=" https://zedoingresso.com.br/usuario/pedidos">
          Click aqui para acessar seus pedidos
        </a>
      </p>

      <p>
        Compras realizadas via promoter, comissário, atlética, ponto de vendas
        ou qualquer outro meio que não seja diretamente ao site, não se
        enquadram nos termos acima ficando sobe responsabilidade da organização
        do evento em realizar o cancelamento e reembolso.
      </p>

      <p>
        Pedidos transferidos ou solicitações efetuados após o inicio do evento
        não serão realizados, independente do motivo.
      </p>

      <h1>Porque meu pedido está com status Pagamento Rejeitado?</h1>

      <h5>
        <em>
          Realizei uma compra porém o pagamento foi rejeitado!
          <br />
          Porque meu pagamento foi recusado? eu tenho saldo!!
        </em>
      </h5>

      <p>
        As compras dos ingressos realizadas com cartão de crédito passam por uma
        avaliação de segurança feita pelo Mercado Pago e podem ser recusadas.
      </p>

      <p>Seguem abaixo os motivos mais comuns para a recusa da compra:</p>

      <ol>
        <li>
          Utilizar cartões e dados de pagamentos de titularidade divergentes ao
          cadastro no site.
          <br />
          Ex. comprar em sua conta utilizando cartões de amigos ou parentes;
        </li>
        <li>
          Algum dado foi informado incorretamente no momento do pagamento,
        </li>
        <li>O cartão usado está expirado ou bloqueado,</li>
        <li>Falta de limite no cartão,</li>
        <li>O banco recusou por problemas ou questões de segurança,</li>
        <li>
          A transação não passou na nossa validação de segurança que protege
          cliente e vendedor contra fraudes.
        </li>
        <li>
          Perfil de compra não aprovado pelo Mercado Pago por questão de
          segurança, baseado em histórico de compras passados.
        </li>
      </ol>

      <p>
        Caso a recusa do cartão seja 1 ou 2, basta corrigir os dados e efetuar
        uma nova compra;
        <br />
        Se a recusa for 3, 4 ou 5 utilize outro cartão;
        <br />
        Se mesmo assim o problema persistir utilize outra forma de pagamento
        (PIX ou BOLETO).
        <br />
        Caso o pagamento da sua compra ainda esteja aparecendo na fatura, não se
        preocupe, em breve o mesmo será estornado automaticamente
      </p>

      <h1>Evento foi cancelado ou adiado?</h1>

      <h5>
        <em>
          Ouvi dizer que o evento foi cancelado, como solicitar o estorno do meu
          dinheiro?
        </em>
      </h5>

      <p>
        Em caso de cancelamento e/ou reagendamento de eventos devido à caso
        fortuito ou força maior, nos termos do 393 do Código Civil Brasileiro,
        por se tratar de eventos extraordinários, o Zé do Ingresso não se
        responsabilizará pelo estorno dos valores dos ingressos, devendo o
        usuário aguardar a manifestação do organizador do evento para devolução
        ou reagendamento da data do evento. Para estes casos a responsabilidade
        da devolução dos valores ficam por conta da organização do evento
        (conforme os
        <a href="/termos-de-uso"> TERMOS DE USO</a>), exceto para solicitações
        efetuadas dentro dos 7 dias após a data da compra, estes sim terão o
        estorno realizado direto pelo site.
      </p>
    </Container>
  )
}

export default DoubsPage
