import clientV2 from 'services/api/v2'

type Response = {
  users: {
    id: string
    name: string
  }[]
  dates: {
    id: string
    name: string
  }[]
  paymentMethods: {
    id: string
    name: string
  }[]
  channels: {
    id: string
    name: string
  }[]
  sectors: {
    id: string
    name: string
  }[]
  batches: {
    id: string
    name: string
  }[]
}

export async function fetchGeneralReportParams(eventId: string) {
  const response = await clientV2.get<Response>(
    `/producer/events/${eventId}/general-report-params`,
  )
  return response.data
}
