import { Container, Paper } from '@mui/material'
import React from 'react'

const UseTermsPage: React.FC = () => {
  return (
    <Container sx={{ padding: 2, marginY: 2 }} component={Paper}>
      <h1>Termos de Uso - Zé do Ingresso</h1>

      <h2>1. Introdução</h2>
      <p>
        O Zé do Ingresso é uma plataforma intermediária que conecta
        organizadores de eventos e consumidores, facilitando a venda de
        ingressos. Não somos responsáveis pela produção, organização ou promoção
        dos eventos listados na plataforma.
      </p>
      <p>
        Ao utilizar o Zé do Ingresso, você concorda com os termos e condições
        descritos abaixo, em conformidade com o Código de Defesa do Consumidor
        (Lei nº 8.078/1990) e com a Lei Geral de Proteção de Dados (Lei nº
        13.709/2018).
      </p>

      <h2>2. Cadastro e Dados Pessoais</h2>
      <ul>
        <li>O cadastro na plataforma é gratuito.</li>
        <li>
          O usuário deve fornecer informações completas, atualizadas e verídicas
          ao se cadastrar.
        </li>
        <li>
          Ao se cadastrar, você autoriza a coleta e tratamento de seus dados
          pessoais em conformidade com a Lei Geral de Proteção de Dados (LGPD -
          Lei nº 13.709/2018).
        </li>
        <li>
          O Zé do Ingresso não armazena informações bancárias. Os pagamentos são
          processados por plataformas terceirizadas que garantem a segurança dos
          dados.
        </li>
        <li>
          O acesso é realizado pelo site oficial{' '}
          <a href="https://www.zedoingresso.com.br">www.zedoingresso.com.br</a>{' '}
          e pelos aplicativos oficiais.
        </li>
        <li>
          Nunca compartilhe sua senha. A recuperação será feita apenas por
          e-mail ou telefone cadastrado através de nossos canais oficiais
          (WhatsApp).
        </li>
      </ul>

      <h2>3. Suspensão ou Encerramento de Conta</h2>
      <p>O Zé do Ingresso pode suspender ou encerrar contas em casos de:</p>
      <ul>
        <li>Violação dos Termos de Uso;</li>
        <li>Práticas fraudulentas ou criminosas;</li>
        <li>Prejuízo a terceiros ou à plataforma;</li>
        <li>
          Venda irregular de ingressos (cambismo), conforme previsto na Lei nº
          14.597/2023;
        </li>
        <li>Uso de informações falsas ou pagamentos fraudulentos.</li>
      </ul>

      <h2>4. Pagamentos</h2>
      <ul>
        <li>
          Os pagamentos estão sujeitos à aprovação da instituição financeira e
          disponibilidade de ingressos.
        </li>
        <li>
          Compras por cartão de crédito incluem taxa de processamento de 3% não
          reembolsável.
        </li>
        <li>
          A taxa administrativa não será reembolsada em caso de cancelamento.
        </li>
        <li>
          Compras feitas por terceiros (promoters, comissários, atléticas, etc.)
          são de responsabilidade da organização do evento.
        </li>
        <li>
          O Zé do Ingresso não se responsabiliza por vendas realizadas fora dos
          canais de venda oficiais (site e ponto de venda físico).
        </li>
      </ul>

      <h2>5. Responsabilidades do Usuário</h2>
      <p>O usuário se compromete a:</p>
      <ul>
        <li>Fornecer informações verdadeiras e atualizadas;</li>
        <li>
          Conferir todos os detalhes do ingresso antes de finalizar a compra;
        </li>
        <li>Cuidar da segurança do ingresso após a compra;</li>
        <li>Apresentar documento de identidade ao acessar o evento;</li>
        <li>
          Isentar o Zé do Ingresso de responsabilidade pela execução do evento.
        </li>
      </ul>

      <h2>6. Ingressos</h2>
      <ul>
        <li>
          Os ingressos são enviados por e-mail após a confirmação do pagamento e
          ficam disponíveis na área Meus Ingressos.
        </li>
        <li>
          Cada ingresso possui um QR Code único para validação na entrada do
          evento.
        </li>
        <li>
          Em caso de perda, extravio ou roubo do ingresso, o usuário deve
          notificar imediatamente o Zé do Ingresso.
        </li>
        <li>
          Ingressos de meia-entrada e descontos seguem as regras dos
          organizadores, respeitando a Lei nº 12.933/2013. Consulte as políticas
          específicas do evento.
        </li>
      </ul>

      <h2>7. Cancelamento e Reembolso</h2>
      <ul>
        <li>
          O cancelamento pode ser solicitado em até 7 dias após a compra, exceto
          nas 48 horas anteriores ao evento, conforme o artigo 49 do Código de
          Defesa do Consumidor (Lei nº 8.078/1990).
        </li>
        <li>Após o evento, não há possibilidade de reembolso.</li>
        <li>
          Apenas o titular da compra pode solicitar o reembolso, e este será
          realizado somente o valor referente aos ingressos.
        </li>
        <li>O prazo para reembolso é de até 30 dias após a solicitação.</li>
        <li>
          Em casos de cancelamento por força maior, o estorno é de
          responsabilidade do organizador do evento.
        </li>
        <li>
          Compras realizadas fora do site oficial não se enquadram nestes
          termos.
        </li>
      </ul>

      <h2>8. Transferência de Ingressos</h2>
      <ul>
        <li>
          O usuário pode transferir a titularidade do ingresso através da
          plataforma.
        </li>
        <li>Após a transferência, um novo QR Code será gerado.</li>
        <li>
          O Zé do Ingresso não se responsabiliza por ingressos comprados e
          transferidos de terceiros.
        </li>
        <li>O ingresso pode ser transferido somente uma única vez.</li>
      </ul>

      <h2>9. Responsabilidade dos Organizadores</h2>
      <ul>
        <li>
          Cumprir as leis aplicáveis e garantir a realização do evento conforme
          divulgado;
        </li>
        <li>
          Emitir notas fiscais referentes aos ingressos vendidos, em
          conformidade com a legislação fiscal vigente;
        </li>
        <li>Gerenciar cancelamentos e reembolsos em casos de força maior.</li>
      </ul>

      <h2>10. Disposições Gerais</h2>
      <p>
        Ao se cadastrar e utilizar o Zé do Ingresso, você aceita integralmente
        os termos aqui descritos. Em caso de dúvidas, entre em contato pelos
        canais oficiais disponíveis no site.
      </p>

      <p>
        Estes termos podem ser alterados a qualquer momento, sem aviso prévio.
        Recomendamos a leitura periódica para se manter informado.
      </p>

      <p>
        <em>Ultima atualização: 18 de fevereiro de 2025.</em>
      </p>
    </Container>
  )
}

export default UseTermsPage
