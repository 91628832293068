import { useMutation, useQuery } from '@tanstack/react-query'
import {
  fetchGeneralReport,
  type GeneralReportType,
} from 'http/producer/fetch-general-report'
import { fetchGeneralReportParams } from 'http/producer/fetch-general-report-params'
import type { GeneralEventReportType } from 'pages/promoter/GeneralReport'
import { useState } from 'react'

export function useGeneralReport(eventId: string) {
  const [generalReport, setGeneralReport] = useState<GeneralReportType | null>(
    null,
  )

  const query = useQuery({
    queryKey: ['general-report', eventId],
    queryFn: () => fetchGeneralReportParams(eventId),
  })

  const mutation = useMutation({
    mutationFn: (body: GeneralEventReportType) =>
      fetchGeneralReport(eventId, body),
    onSuccess: (data) => {
      setGeneralReport(data)
    },
  })

  return {
    params: {
      userOptions: query.data?.users ?? [],
      datesOptions: query.data?.dates ?? [],
      channelOptions: query.data?.channels ?? [],
      paymentMethodOptions: query.data?.paymentMethods ?? [],
      batchOptions: query.data?.batches ?? [],
      sectorOptions: query.data?.sectors ?? [],
    },
    generateReport: mutation.mutateAsync,
    isLoading: query.isLoading || mutation.isPending,
    generalReport,
  }
}
