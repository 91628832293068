/* eslint-disable camelcase */
import { Search } from '@mui/icons-material'
import { Box, Card, CardHeader, InputAdornment, TextField } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { Loading } from 'components'
import ErrorComponent from 'components/error-component'
import dayjs from 'dayjs'
import { useSuspectedOrders } from 'hooks/use-suspected-orders'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { datagrid } from 'utils'
import { moneyFormatter } from 'utils/formatter'

export default function PedidosSuspeitos() {
  const [searchQuery, setSearchQuery] = useState('')

  const { data, isLoading, error } = useSuspectedOrders()

  if (isLoading) return <Loading />

  const filteredOrders =
    data?.orders.filter(
      (order) =>
        order.id.includes(searchQuery) ||
        order.user?.id.includes(searchQuery) ||
        order.user?.fullName.includes(searchQuery) ||
        order.user?.email.includes(searchQuery) ||
        order.user?.cpf.includes(searchQuery) ||
        order.user?.phone.includes(searchQuery),
    ) ?? []

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 200,
      renderCell: (params) => (
        <Link to={`/admin/pedidos/${params.value}`}>{params.value}</Link>
      ),
    },
    {
      field: 'event',
      headerName: 'Evento',
      width: 400,
      renderCell: (params) => <>{params.row.event?.name}</>,
    },
    {
      field: 'createdAt',
      headerName: 'Data pedido',
      width: 200,
      renderCell: (params) => (
        <>{dayjs(params.value).format('DD/MM/YYYY HH:mm')}</>
      ),
    },
    {
      field: 'fullName',
      headerName: 'Usuário',
      flex: 1,
      renderCell: (params) => (
        <Link to={`/admin/usuarios/${params.row.user?.id}`}>
          {params.row.user?.fullName}
        </Link>
      ),
    },
    {
      field: 'totalAmount',
      headerName: 'Valor total',
      width: 200,
      renderCell: (params) => <>{moneyFormatter.format(params.value)}</>,
    },
  ]

  return (
    <Box display="grid" gap={2}>
      <Card>
        <CardHeader title="Pedidos Suspeitos" />
        <Box p={2}>
          <TextField
            fullWidth
            label="Filtrar"
            placeholder='"Código do pedido", "Usuário", "Email", "CPF" ou "Telefone"'
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </Box>
      </Card>
      <Card>
        <Box p={2}>
          {error ? (
            <ErrorComponent error={error} />
          ) : (
            <DataGrid
              {...datagrid}
              rows={filteredOrders}
              columns={columns}
              loading={isLoading}
              autoHeight
            />
          )}
        </Box>
      </Card>
    </Box>
  )
}
