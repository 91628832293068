import { useMutation, useQuery } from '@tanstack/react-query'
import { usePromoter } from 'contexts/promoterContext'
import { queryClient } from 'lib/react-query'
import { apiV1 } from 'services'
import { getErrorMessage } from 'utils/AppError'

export function useFormProducer(producerId: string) {
  const { addErrorMessage } = usePromoter()

  const query = useQuery({
    queryKey: ['producer-form', producerId],
    queryFn: () => apiV1.producer.promoterService.get(producerId),
  })

  const mutation = useMutation({
    mutationFn: (body: {
      razao_social: string
      cnpj?: string
      email: string
      telefone: string
      capa?: string
      imagem?: string
    }) =>
      apiV1.producer.promoterService.update(producerId, {
        razao_social: body.razao_social,
        email: body.email,
        telefone: body.telefone,
        cnpj: body.cnpj,
        capa: body.capa,
        imagem: body.imagem,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['producer-form', producerId] })
    },
    onError: (error) => {
      addErrorMessage(getErrorMessage(error))
    },
  })

  return {
    producer: query.data?.data,
    isLoading: query.isLoading || mutation.isPending,
    saveProducer: mutation.mutateAsync,
  }
}
