import { Container } from '@mui/material'
import { Loading } from 'components'
import { Typography } from 'components/Typography'
import { useHttp } from 'hooks/useHttp'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import type { ShoppingCart } from 'types/shoppingCart'

type CheckoutSessionResponse = {
  url: string
}

export function NewCheckoutPage() {
  const location = useLocation()
  const { eventId, shoppingCart } = location.state as {
    eventId: string
    shoppingCart: ShoppingCart[]
  }

  const checkoutSession = useHttp<CheckoutSessionResponse>({
    clientType: 'v2',
    method: 'post',
    url: '/checkouts',
    onSuccess({ url }) {
      window.location.replace(url)
    },
  })

  useEffect(() => {
    checkoutSession.execute({
      eventId,
      items: shoppingCart.map((item) => ({
        id: item.id,
        quantity: item.amount,
      })),
      ref: localStorage.getItem(eventId + '_ref'),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container component="main" maxWidth="md" sx={{ py: 10 }}>
      {checkoutSession.isLoading && <Loading />}
      {checkoutSession.error && (
        <Typography>
          Erro ao criar a sessão de checkout. Tente novamente. Se o erro
          persistir, entre em contato com o suporte.
        </Typography>
      )}
    </Container>
  )
}
