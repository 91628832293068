import SaveIcon from '@mui/icons-material/Save'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Autocomplete,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material'
import { FormItem } from 'components'
import { useFormik } from 'formik'
import React from 'react'
import ReactInputMask from 'react-input-mask'
import { useLocation, useNavigate } from 'react-router-dom'
import { apiV1 } from 'services'
import { IBrasilCidade } from 'types/BrasilCidade'
import { ILocationProps } from 'types/locationProps'
import { IUsuario } from 'types/usuario'
import { functionWithLoading } from 'utils'
import { getErrorMessage } from 'utils/AppError'
import validationSchema from './validationSchema'

const CompleteRegistrationPage: React.FC = () => {
  const [loading, setLoading] = React.useState(false)
  const [loadingData, setLoadingData] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [cpfAlreadyExists, setCpfAlreadyExists] = React.useState(false)
  const [phoneAlreadyExists, setPhoneAlreadyExists] = React.useState(false)
  const [user, setUser] = React.useState<IUsuario>({} as IUsuario)
  const [selectedUF, setSelectedUF] = React.useState<string>('SP')
  const [ufs, setUfs] = React.useState<IBrasilCidade[]>([])
  const [cities, setCities] = React.useState<IBrasilCidade[]>([])
  const [loadingUf, setLoadingUf] = React.useState(true)
  const [loadingCity, setLoadingCity] = React.useState(true)
  const [showCPF, setShowCPF] = React.useState(true)
  const [showPhone, setShowPhone] = React.useState(true)

  const navigate = useNavigate()
  const location = useLocation() as ILocationProps
  const from = location.state?.from?.pathname ?? '/'
  const fromState = location.state?.from?.state

  React.useEffect(() => {
    functionWithLoading(
      apiV1.userService
        .getUserByToken()
        .then((res) => {
          localStorage.setItem('user', JSON.stringify(res.data))
          if (res.data.cpf && res.data.ddd && res.data.telefone) {
            navigate(from, { replace: true, state: fromState })
          } else {
            setUser({
              ...res.data,
              telefone: [res.data.ddd, res.data.telefone].join(''),
              nome: [res.data.nome, res.data.sobrenome].join(' ').trim(),
            })
            if (res.data.cpf) setShowCPF(false)
            if (res.data.telefone) setShowPhone(false)
            setSelectedUF(res.data.brasil_cidade?.uf ?? 'SP')
            functionWithLoading(
              apiV1.cityService
                .getUFs()
                .then((resUF) => {
                  setUfs(resUF.data)
                  functionWithLoading(
                    apiV1.cityService
                      .getCities(res.data.brasil_cidade?.uf ?? 'SP')
                      .then((res) => {
                        setCities(res.data)
                      }),
                    setLoadingCity,
                  )
                })
                .catch((error) => console.log(error)),
              setLoadingUf,
            )
          }
        })
        .catch((error) => {
          setErrorMessage(getErrorMessage(error))
        }),
      setLoadingData,
    )
  }, [from, fromState, location?.state?.from, navigate])

  React.useEffect(() => {
    apiV1.cityService
      .getUFs()
      .then((resUF) => {
        setUfs(resUF.data)
        functionWithLoading(
          apiV1.cityService.getCities(selectedUF).then((res) => {
            setCities(res.data)
          }),
          setLoadingCity,
        )
      })
      .catch((error) => console.log(error))
  }, [selectedUF])

  const handleChangeUF = React.useCallback((event) => {
    if (event) {
      setSelectedUF(event.target.value)
      functionWithLoading(
        apiV1.cityService.getCities(event.target.value).then((res) => {
          setCities(res.data)
        }),
        setLoadingCity,
      )
    }
  }, [])

  const handleSubmit = React.useCallback(
    (values: IUsuario) => {
      functionWithLoading(
        apiV1.userService
          .completeRegistration(values)
          .then(({ data }) => {
            localStorage.setItem('user', JSON.stringify(data))
            if (!data.telefone_confirmado) {
              navigate('/usuario/verificar-numero-telefone', {
                replace: true,
                state: {
                  phoneNumber: [data.ddd, data.telefone].join(''),
                  from: location?.state?.from,
                },
              })
            } else {
              navigate(from, { replace: true, state: fromState })
            }
          })
          .catch((error) => console.log(error)),
        setLoading,
      )
    },
    [from, fromState, location, navigate],
  )

  const formik = useFormik({
    initialValues: user,
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema,
    onSubmit: (values) => {
      functionWithLoading(
        apiV1.userService
          .existInDatabase({
            cpf: !user.cpf ? values.cpf ?? '' : '',
            phone: !user.telefone ? values.telefone ?? '' : '',
          })
          .then((res) => {
            !user.cpf && setCpfAlreadyExists(res.data.cpf)
            !user.telefone && setPhoneAlreadyExists(res.data.phone)
            if (!(res.data.cpf || res.data.phone)) {
              handleSubmit(values)
            }
          })
          .catch((err) => {
            setErrorMessage(err.response?.data.message ?? err.message)
          }),
        setLoading,
      )
    },
  })

  return (
    <Container
      component="form"
      onSubmit={formik.handleSubmit}
      noValidate
      maxWidth="md"
      sx={{ my: 2 }}
    >
      <Paper sx={{ p: 2 }}>
        <Typography variant="h4">Complete seu cadastro</Typography>
        {errorMessage && (
          <Alert severity="error" onClose={() => setErrorMessage('')}>
            {errorMessage}
          </Alert>
        )}
        {cpfAlreadyExists && (
          <Alert
            severity="error"
            sx={{ mt: 1 }}
            onClose={() => setCpfAlreadyExists(false)}
          >
            CPF já cadastrado no site.
          </Alert>
        )}
        {phoneAlreadyExists && (
          <Alert
            severity="error"
            sx={{ mt: 1 }}
            onClose={() => setPhoneAlreadyExists(false)}
          >
            Telefone já cadastrado no site.
          </Alert>
        )}
        {loadingData ? (
          <Grid container spacing={2} my={2}>
            <Grid item xs={12} md={6}>
              <Skeleton />
            </Grid>
            <Grid item xs={12} md={6}>
              <Skeleton />
            </Grid>
            <Grid item xs={12}>
              <Skeleton />
            </Grid>
            <Grid item xs={12} md={6}>
              <Skeleton />
            </Grid>
            <Grid item xs={12} md={6}>
              <Skeleton />
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2} my={2}>
            {showCPF && (
              <Grid item xs={12} sm={6}>
                <ReactInputMask
                  mask="999.999.999-99"
                  value={formik.values.cpf ?? ''}
                  onChange={formik.handleChange}
                  disabled={false}
                >
                  <FormItem.CPF
                    label="CPF"
                    name="cpf"
                    error={
                      (formik.touched.cpf && Boolean(formik.errors.cpf)) ||
                      cpfAlreadyExists
                    }
                    helperText={formik.touched.cpf && formik.errors.cpf}
                  />
                </ReactInputMask>
              </Grid>
            )}
            {showPhone && (
              <Grid item xs={12} sm={6}>
                <ReactInputMask
                  mask="(99) 99999-9999"
                  value={formik.values.telefone ?? ''}
                  onChange={formik.handleChange}
                  disabled={false}
                >
                  <FormItem.TextField
                    label="Telefone"
                    name="telefone"
                    error={
                      (formik.touched.telefone &&
                        Boolean(formik.errors.telefone)) ||
                      phoneAlreadyExists
                    }
                    helperText={
                      formik.touched.telefone && formik.errors.telefone
                    }
                  />
                </ReactInputMask>
              </Grid>
            )}

            <Grid item xs={12}>
              <FormItem.TextField
                label="Nome completo"
                name="nome"
                value={formik.values.nome ?? ''}
                onChange={formik.handleChange}
                error={Boolean(formik.touched.nome && formik.errors.nome)}
                helperText={formik.touched.nome && formik.errors.nome}
                autoComplete="on"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormItem.TextField
                label="Data de nascimento"
                name="data_nascimento"
                value={formik.values.data_nascimento ?? new Date()}
                error={Boolean(
                  formik.touched.data_nascimento &&
                    formik.errors.data_nascimento,
                )}
                helperText={
                  formik.touched.data_nascimento &&
                  formik.errors.data_nascimento
                }
                onChange={formik.handleChange}
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="sexo-select-label">Sexo</InputLabel>
                <Select
                  labelId="sexo-select-label"
                  id="sexo-select"
                  label="Sexo"
                  name="sexo"
                  value={formik.values.sexo ?? '0'}
                  error={formik.touched.sexo && Boolean(formik.errors.sexo)}
                  onChange={formik.handleChange}
                  defaultValue="0"
                >
                  <MenuItem value={'Masculino'}>Masculino</MenuItem>
                  <MenuItem value={'Feminino'}>Feminino</MenuItem>
                  <MenuItem value={'Prefiro não informar'}>
                    Prefiro não informar
                  </MenuItem>
                  <MenuItem value={'0'}>Selecione</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={2}>
              {loadingUf ? (
                <Skeleton />
              ) : (
                <FormControl fullWidth>
                  <InputLabel id="uf-select-label">UF</InputLabel>
                  <Select
                    labelId="uf-select-label"
                    id="uf-select"
                    label="UF"
                    value={selectedUF}
                    onChange={handleChangeUF}
                  >
                    {ufs?.map((city) => (
                      <MenuItem key={'uf_' + city.uf} value={city.uf}>
                        {city.uf}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>
            <Grid item xs={12} sm={10}>
              {loadingCity ? (
                <Skeleton />
              ) : (
                <Autocomplete
                  disablePortal
                  id="cod_brasil_cidade-select"
                  options={cities}
                  getOptionLabel={(option) => option.cidade}
                  onChange={(e, value) =>
                    formik.setFieldValue(
                      'cod_brasil_cidade',
                      value?.codigo_cidade ?? 0,
                    )
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Cidade"
                      name="cod_brasil_cidade"
                      value={formik.values.cod_brasil_cidade ?? 0}
                      error={Boolean(
                        formik.touched.cod_brasil_cidade &&
                          formik.errors.cod_brasil_cidade,
                      )}
                    />
                  )}
                  loading={loadingCity}
                />
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
              }}
            >
              <LoadingButton
                loadingPosition="end"
                endIcon={<SaveIcon />}
                loading={loading}
                disabled={loading}
                variant="contained"
                type="submit"
              >
                Salvar alterações
              </LoadingButton>
            </Grid>
          </Grid>
        )}
      </Paper>
    </Container>
  )
}

export default CompleteRegistrationPage
