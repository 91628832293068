import clientAdmin from 'services/api/v1/admin/client'

export type SuspectedUser = {
  userId: string
  name: string
  lastOrderId: string
  lastOrderDate: Date
  totalOrders: number
  totalOrdersAmount: number
  totalTickets: number
  totalTicketsAmount: number
  totalAlerts: number
  sharedCreditCard: string
  isBlocked: boolean
  analyzeFinancial: boolean
}

export async function fetchSuspectedUsers() {
  const response = await clientAdmin.post<{ report: SuspectedUser[] }>(
    `/users/suspected`,
  )
  return response.data
}
