import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  type SelectChangeEvent,
} from '@mui/material'
import { useState } from 'react'
import { Controller, type Control } from 'react-hook-form'

interface MultiSelectCheckmarkProps {
  name: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>
  options: { id: string; name: string }[]
  label: string
  size?: 'small' | 'medium'
}

export function MultiSelectCheckmark({
  name,
  control,
  options,
  label,
  size = 'small',
}: MultiSelectCheckmarkProps) {
  const [selectedValues, setSelectedValues] = useState<string[]>([])

  const handleChange = (
    event: SelectChangeEvent<string[]>,
    field: { onChange: (value: string[]) => void; value: string[] },
  ) => {
    const value = event.target.value
    if (value.includes('all')) {
      field.onChange(
        selectedValues.length === options.length
          ? []
          : options.map((opt) => opt.id),
      )
      setSelectedValues(
        selectedValues.length === options.length
          ? []
          : options.map((opt) => opt.id),
      )
    } else {
      field.onChange(value as string[])
      setSelectedValues(value as string[])
    }
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControl fullWidth margin="normal">
          <InputLabel>{label}</InputLabel>
          <Select
            multiple
            value={field.value}
            onChange={(e) => handleChange(e, field)}
            renderValue={(selected) =>
              options
                .filter((opt) => selected.includes(opt.id))
                .map((opt) => opt.name)
                .join(', ')
            }
            size={size}
            variant="filled"
          >
            <MenuItem value="all">
              <Checkbox checked={selectedValues.length === options.length} />
              <ListItemText primary="Selecionar Todos" />
            </MenuItem>
            {options.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                <Checkbox checked={field.value.includes(option.id)} />
                <ListItemText primary={option.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  )
}
