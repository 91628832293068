/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import { LocalActivity, PhoneIphone, Print, Web } from '@mui/icons-material'
import Close from '@mui/icons-material/Close'
import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  Modal,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import dayjs from 'dayjs'
import { SaqueDTO } from 'dto/SaqueDTO'
import QrCodeWithLogo from 'qrcode-with-logos'
import * as React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { apiV1 } from 'services'
import { IEvento } from 'types/evento'
import { functionWithLoading } from 'utils'
import { AppError } from 'utils/AppError'
import { moneyFormatter } from 'utils/formatter'
import { getDaysArray } from 'utils/functions'
import LogoQRCode from '../assets/img/logo.jpg'
import SalesByPeriodChart from '../pages/admin/EventDetailsPage/SalesByPeriodChart'
import SectorChart from '../pages/admin/EventDetailsPage/SectorChart'
import Loading from './Loading'
import SalesByPeriodHour from 'pages/admin/EventDetailsPage/SalesByPeriodHour'

type SalesResumeResponseBatch = {
  ingresso_id: number
  ingresso_titulo: string
  valor_venda: number
  valor_repasse: number
  taxa_produtor: number
  qtd_lotes: number
  qtd_app: number
  qtd_site: number
  qtd_fisico: number
  qtd_ingressos: number
  valor_app: number
  valor_site: number
  valor_fisico: number
  valor_total: number
}

export type SalesResumeResponseSectors = {
  setor_id: string
  setor_titulo: string
  ingressos: SalesResumeResponseBatch[]
}

type SalesResumeResponseCoupons = {
  codigo: string
  cod_tipo: number
  valor: number
  quantidade: number
  valor_total: number
}

export type SalesResumeResponse = {
  sectors: SalesResumeResponseSectors[]
  evento: IEvento
  _vendas_por_dia: any[]
  withdrawals: SaqueDTO[]
  coupons: SalesResumeResponseCoupons[]
  subEvents: { id: number; titulo: string }[]
  salesPerDayAndHour: { dia: number; hora: number; total: number }[]
}

interface SalesResumeProps {
  eventId: number
  isAdmin?: boolean
}

type PhysicalSalesProps = {
  dinheiro: number
  pix: number
  debito: number
  credito: number
  valor_total_vendas: number

  taxa_dinheiro: number
  taxa_pix: number
  taxa_debito: number
  taxa_credito: number

  valor_taxa_dinheiro: number
  valor_taxa_pix: number
  valor_taxa_debito: number
  valor_taxa_credito: number
  valor_total_taxas: number
}

const SalesResume = ({ eventId, isAdmin = false }: SalesResumeProps) => {
  const [salesResume, setSalesResume] = React.useState<SalesResumeResponse>()
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState('')
  const [days, setDays] = React.useState<any[]>([])
  const [sectorByPeriodChart, setSectorByPeriodChart] = React.useState<any[]>(
    [],
  )
  const [physicalSales, setPhysicalSales] = React.useState<PhysicalSalesProps>()

  const [isLoading, setIsLoading] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const navigate = useNavigate()

  const handleNavigateToSubEvent = React.useCallback(
    (id: number) => {
      if (isAdmin) {
        window.open(`/admin/events/${id}`, '_blank')
      } else {
        window.open(`/organizador/eventos/${id}`, '_blank')
      }
    },
    [isAdmin],
  )

  React.useEffect(() => {
    if (salesResume) {
      const initialDate = dayjs(salesResume.evento.data_entrar)
        .add(-3, 'hours')
        .toDate()
      const finalDate = dayjs(salesResume.evento.data_encerrar_vendas).add(
        -3,
        'hours',
      )
      const rangeDates = getDaysArray(
        initialDate,
        finalDate.isAfter(dayjs())
          ? dayjs().toDate()
          : dayjs(salesResume.evento.data_encerrar_vendas).toDate(),
      )

      const groupBySector = salesResume._vendas_por_dia.reduce(
        (group, sector) => {
          const { setor_id, setor } = sector
          const currentGroup = group?.find((x: any) => x.setor_id === setor_id)
          if (!currentGroup) {
            group?.push({
              setor_id,
              setor,
              days: salesResume._vendas_por_dia
                .filter((row) => row.setor_id === setor_id)
                .reduce((groupDay, dayRow) => {
                  const {
                    dia,
                    lote_id,
                    lote,
                    quantidade_ingressos,
                    valor_ingressos,
                  } = dayRow
                  groupDay[dia] = groupDay[dia] ?? []
                  groupDay[dia].push({
                    dia,
                    lote_id,
                    lote,
                    quantidade_ingressos,
                    valor_ingressos,
                  })
                  return groupDay
                }, []),
            })
          }
          return group
        },
        [],
      )
      setDays(rangeDates)
      setSectorByPeriodChart(groupBySector)
    }
  }, [salesResume])

  const handleDownloadQRCode = React.useCallback(() => {
    new QrCodeWithLogo({
      content: `https://zedoingresso.com.br/evento/${salesResume?.evento.id}/${salesResume?.evento.id_url}`,
      width: 1024,
      download: true,
      downloadName: `Evento_${salesResume?.evento.id}`,
      logo: {
        src: LogoQRCode,
        borderRadius: 0,
        borderSize: 0.008,
      },
    }).downloadImage(`Evento_${salesResume?.evento.id}`)
  }, [salesResume?.evento.id, salesResume?.evento.id_url])

  const load = React.useCallback(() => {
    if (isAdmin) {
      functionWithLoading(
        apiV1.admin.eventService
          .getDetails(Number(eventId))
          .then(({ data }) => {
            setSalesResume(data)
          })
          .catch((err) => setError(err.response?.data.message ?? err.message)),
        setLoading,
      )
    } else {
      functionWithLoading(
        apiV1.producer.eventService
          .salesResume(Number(eventId))
          .then(({ data }) => {
            setSalesResume(data)
          })
          .catch((err) => setError(err.response?.data.message ?? err.message)),
        setLoading,
      )
    }
  }, [eventId, isAdmin])

  const handleSeeMorePhysicalSales = React.useCallback(async () => {
    try {
      setIsLoading(true)
      const { data } = await (isAdmin
        ? apiV1.admin.eventService.physicalSalesDetails(Number(eventId))
        : apiV1.producer.eventService.physicalSalesDetails(Number(eventId)))
      setPhysicalSales(data.physicalSales)
      handleOpen()
    } catch (error) {
      const isAppError = error instanceof AppError

      const message = isAppError
        ? error.message
        : 'Ocorreu um erro ao carregar as informações, tente novamente!'

      setError(message)
    } finally {
      setIsLoading(false)
    }
  }, [eventId, isAdmin])

  React.useEffect(() => {
    load()
  }, [load])

  if (loading || !salesResume)
    return (
      <Box
        sx={{
          height: '70vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress color="inherit" />
      </Box>
    )

  let withdrawalAvailable = false

  if (!isAdmin) {
    const sessionPromoter = localStorage.getItem('promoter')
    if (sessionPromoter) {
      const promoter = JSON.parse(sessionPromoter)
      withdrawalAvailable =
        promoter.id === salesResume.evento.promoter.responsavelId &&
        !salesResume.evento.acertado &&
        !salesResume.evento.cancelado &&
        !salesResume.evento.venda_suspensa &&
        (salesResume.evento.antecipar_saque ||
          dayjs(salesResume.evento.data_final).isBefore(dayjs()))
    }
  }

  return (
    <Grid container spacing={1} p={1}>
      {error && (
        <Grid item xs={12}>
          <Alert severity="error" sx={{ mt: 1 }} onClose={() => setError('')}>
            {error}
          </Alert>
        </Grid>
      )}
      {withdrawalAvailable && (
        <Grid item xs={12}>
          <Alert severity="success" sx={{ mt: 1 }}>
            O saque do seu evento já está disponível{' '}
            <Link to={`/organizador/eventos/${eventId}/solicitacao-de-saque`}>
              solicite aqui
            </Link>
          </Alert>
        </Grid>
      )}
      {salesResume.evento.ingresso?.length === 0 && (
        <Grid item xs={12}>
          <Alert severity="warning" sx={{ mt: 2 }}>
            Não existem ingressos/lotes cadastrados para este evento.
          </Alert>
        </Grid>
      )}
      <Grid item xs={12} sm={6}>
        {/* <Grid item xs={12} sm={4}>
          <Card
            variant="outlined"
            color="#FFFFFF"
            sx={{ backgroundColor: "#ed5454", borderColor: "#bd4242", p: 2 }}
          >
            <Typography variant="h4" color="#FFFFFF" mb={1}>
              Link
            </Typography>
            <Stack direction="row">
              <PhosphorIcons.Link size={48} color="#FFFFFF" />
              <Stack
                sx={{
                  borderLeft: "solid #FFFFFF 2px",
                  justifyContent: "center",
                  ml: 1,
                  pl: 1,
                }}
              >
                <Typography variant="subtitle2" color="#FFFFFF">
                  Qtd.: 0
                </Typography>
                <Typography variant="subtitle2" color="#FFFFFF">
                  Total: {moneyFormatter.format(0)}
                </Typography>
              </Stack>
            </Stack>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card
            variant="outlined"
            color="#FFFFFF"
            sx={{ backgroundColor: "#5456ed", borderColor: "#4c42bd", p: 2 }}
          >
            <Typography variant="h4" color="#FFFFFF" mb={1}>
              App
            </Typography>
            <Stack direction="row">
              <PhosphorIcons.DeviceMobile size={48} color="#FFFFFF" />
              <Stack
                sx={{
                  borderLeft: "solid #FFFFFF 2px",
                  justifyContent: "center",
                  ml: 1,
                  pl: 1,
                }}
              >
                <Typography variant="subtitle2" color="#FFFFFF">
                  Qtd.: 0
                </Typography>
                <Typography variant="subtitle2" color="#FFFFFF">
                  Total: {moneyFormatter.format(0)}
                </Typography>
              </Stack>
            </Stack>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card
            variant="outlined"
            color="#FFFFFF"
            sx={{
              backgroundColor: "#54ed7d",
              borderColor: "#42bd48",
              p: 2,
            }}
          >
            <Typography variant="h4" color="#FFFFFF" mb={1}>
              PDV
            </Typography>
            <Stack direction="row">
              <PhosphorIcons.Printer size={48} color="#FFFFFF" />
              <Stack
                sx={{
                  borderLeft: "solid #FFFFFF 2px",
                  justifyContent: "center",
                  ml: 1,
                  pl: 1,
                }}
              >
                <Typography variant="subtitle2" color="#FFFFFF">
                  Qtd.: 0
                </Typography>
                <Typography variant="subtitle2" color="#FFFFFF">
                  Total: {moneyFormatter.format(0)}
                </Typography>
              </Stack>
            </Stack>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card
            variant="outlined"
            color="#FFFFFF"
            sx={{ backgroundColor: "#ed54d8", borderColor: "#bd42a4", p: 2 }}
          >
            <Typography variant="h4" color="#FFFFFF" mb={1}>
              Total
            </Typography>
            <Stack direction="row">
              <PhosphorIcons.DeviceMobile size={48} color="#FFFFFF" />
              <Stack
                sx={{
                  borderLeft: "solid #FFFFFF 2px",
                  justifyContent: "center",
                  ml: 1,
                  pl: 1,
                }}
              >
                <Typography variant="subtitle2" color="#FFFFFF">
                  Qtd.: 0
                </Typography>
                <Typography variant="subtitle2" color="#FFFFFF">
                  Total: {moneyFormatter.format(0)}
                </Typography>
              </Stack>
            </Stack>
          </Card>
        </Grid> */}

        <Card>
          <CardHeader
            avatar={
              <Avatar sx={{ bgcolor: (theme) => theme.palette.primary.main }}>
                <Web />
              </Avatar>
            }
            action={
              <Stack>
                <Typography variant="subtitle1" textAlign="right">
                  {moneyFormatter.format(
                    salesResume.sectors.reduce(
                      (total, setor) =>
                        total +
                        setor.ingressos.reduce(
                          (soma, ingresso) => soma + ingresso.valor_site,
                          0,
                        ),
                      0,
                    ),
                  )}
                </Typography>
                {salesResume?.withdrawals?.filter(
                  (x) =>
                    x.tipo_saque === 'VENDA_DIGITAL' &&
                    x.cod_saque_status === 2,
                ).length > 0 && (
                  <Typography variant="subtitle1" color="red">
                    Valor sacado -{' '}
                    {moneyFormatter.format(
                      salesResume.withdrawals
                        .filter(
                          (x) =>
                            x.tipo_saque === 'VENDA_DIGITAL' &&
                            x.cod_saque_status === 2,
                        )
                        .reduce(
                          (total, saque) => Number(total) + Number(saque.valor),
                          0,
                        ),
                    )}
                  </Typography>
                )}
                {salesResume?.coupons?.length > 0 && (
                  <Typography variant="subtitle1" color="red">
                    Cupons utilizados -{' '}
                    {moneyFormatter.format(
                      salesResume.coupons.reduce(
                        (total, coupon) => total + Number(coupon.valor_total),
                        0,
                      ),
                    )}
                  </Typography>
                )}
              </Stack>
            }
            title="Site"
            subheader={
              'Qtd. ' +
              salesResume.sectors.reduce(
                (total, setor) =>
                  total +
                  setor.ingressos.reduce(
                    (soma, ingresso) => soma + ingresso.qtd_site,
                    0,
                  ),
                0,
              )
            }
          />{' '}
          <Divider />
          <CardHeader
            avatar={
              <Avatar sx={{ bgcolor: (theme) => theme.palette.primary.main }}>
                <PhoneIphone />
              </Avatar>
            }
            action={
              <Typography variant="subtitle1">
                {moneyFormatter.format(
                  salesResume.sectors.reduce(
                    (total, setor) =>
                      total +
                      setor.ingressos.reduce(
                        (soma, ingresso) => soma + ingresso.valor_app,
                        0,
                      ),
                    0,
                  ),
                )}
              </Typography>
            }
            title="App"
            subheader={
              'Qtd. ' +
              salesResume.sectors.reduce(
                (total, setor) =>
                  total +
                  setor.ingressos.reduce(
                    (soma, ingresso) => soma + ingresso.qtd_app,
                    0,
                  ),
                0,
              )
            }
          />
          <Divider />
          <CardHeader
            avatar={
              <Avatar sx={{ bgcolor: (theme) => theme.palette.primary.main }}>
                <Print />
              </Avatar>
            }
            action={
              <Stack>
                <Stack direction="row" justifyContent="flex-end">
                  <Button
                    variant="text"
                    onClick={handleSeeMorePhysicalSales}
                    size="small"
                    color="info"
                  >
                    Ver mais...
                  </Button>
                  <Typography variant="subtitle1" textAlign="right">
                    {moneyFormatter.format(
                      salesResume.sectors.reduce(
                        (total, setor) =>
                          total +
                          setor.ingressos.reduce(
                            (soma, ingresso) => soma + ingresso.valor_fisico,
                            0,
                          ),
                        0,
                      ),
                    )}
                  </Typography>
                </Stack>
                {salesResume?.withdrawals?.filter(
                  (x) =>
                    x.tipo_saque === 'VENDA_FISICA' && x.cod_saque_status === 2,
                ).length > 0 && (
                  <Typography variant="subtitle1" color="red">
                    Valor sacado -{' '}
                    {moneyFormatter.format(
                      salesResume.withdrawals
                        .filter(
                          (x) =>
                            x.tipo_saque === 'VENDA_FISICA' &&
                            x.cod_saque_status === 2,
                        )
                        .reduce(
                          (total, saque) => Number(total) + Number(saque.valor),
                          0,
                        ),
                    )}
                  </Typography>
                )}
              </Stack>
            }
            title="Físico"
            subheader={
              'Qtd. ' +
              salesResume.sectors.reduce(
                (total, setor) =>
                  total +
                  setor.ingressos.reduce(
                    (soma, ingresso) => soma + ingresso.qtd_fisico,
                    0,
                  ),
                0,
              )
            }
          />
          <Divider />
          <CardHeader
            avatar={
              <Avatar sx={{ bgcolor: (theme) => theme.palette.primary.main }}>
                <LocalActivity />
              </Avatar>
            }
            action={
              <Typography variant="subtitle1">
                {moneyFormatter.format(
                  salesResume.sectors.reduce(
                    (total, setor) =>
                      total +
                      setor.ingressos.reduce(
                        (soma, ingresso) => soma + ingresso.valor_total,
                        0,
                      ),
                    0,
                  ) -
                    salesResume.coupons.reduce(
                      (total, coupon) => total + Number(coupon.valor_total),
                      0,
                    ),
                )}
              </Typography>
            }
            title="Total"
            subheader={
              'Qtd. ' +
              salesResume.sectors.reduce(
                (total, setor) =>
                  total +
                  setor.ingressos.reduce(
                    (soma, ingresso) => soma + ingresso.qtd_ingressos,
                    0,
                  ),
                0,
              )
            }
          />
        </Card>

        {/* <Alert severity="warning" sx={{ mt: 2 }}>
          Existem pedidos cancelados.
        </Alert> */}

        <Button
          variant="outlined"
          onClick={() => navigate(`/organizador/eventos/${eventId}/pedidos`)}
          sx={{ my: 1, mr: 2 }}
        >
          Lista de pedidos
        </Button>

        <Button
          variant="outlined"
          onClick={() =>
            navigate(`/organizador/eventos/${eventId}/participantes`)
          }
          sx={{ my: 1, mr: 2 }}
        >
          Lista de participantes
        </Button>

        <Button
          variant="outlined"
          onClick={handleDownloadQRCode}
          sx={{ my: 1, mr: 2 }}
        >
          QR Code do evento
        </Button>

        {isAdmin && (
          <Button
            variant="outlined"
            onClick={() => navigate(`/admin/events/${eventId}/fechamento`)}
          >
            Fechamento
          </Button>
        )}
        {isAdmin && (
          <Button
            variant="outlined"
            onClick={() => navigate(`/admin/events/${eventId}/servicos`)}
          >
            Serviços
          </Button>
        )}

        <Grid item xs={12} sm={6}>
          <Button
            LinkComponent="a"
            href={`https://zedoingresso.com.br/evento/${eventId}/${salesResume.evento.id_url}`}
            target="_blank"
            variant="outlined"
            sx={{ my: 1, mr: 2 }}
          >
            Visualizar evento
          </Button>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Button
            variant="outlined"
            onClick={() =>
              navigate(`/organizador/eventos/${eventId}/relatorios/geral`)
            }
          >
            Relatório geral
          </Button>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={6}>
        <SectorChart sectors={salesResume.sectors} />
      </Grid>

      <Grid item xs={12}>
        <SalesByPeriodChart sectors={sectorByPeriodChart} days={days} />
      </Grid>

      {salesResume.evento.bar && (
        <Grid item xs={12}>
          <SalesByPeriodHour
            salesPerDayAndHour={salesResume.salesPerDayAndHour}
          />
        </Grid>
      )}

      {salesResume.sectors.map((sector) => (
        <Grid item xs={12} key={'sector_' + sector.setor_id}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{sector.setor_titulo}</TableCell>
                  <TableCell align="right">Valor</TableCell>
                  <TableCell align="right">Taxa produtor</TableCell>
                  <TableCell align="right">Lote vendidos</TableCell>
                  <TableCell align="right">Ingressos emitidos</TableCell>
                  <TableCell align="right">APP</TableCell>
                  <TableCell align="right">Físico</TableCell>
                  <TableCell align="right">Site</TableCell>
                  <TableCell align="right">Valor Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sector.ingressos.map((row) => (
                  <TableRow
                    key={'row_' + row.ingresso_id}
                    sx={{
                      '&:last-child td, &:last-child th': {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {row.ingresso_titulo}
                    </TableCell>
                    <TableCell align="right">
                      {moneyFormatter.format(row.valor_venda)}
                    </TableCell>
                    <TableCell align="right">
                      {moneyFormatter.format(row.taxa_produtor)}
                    </TableCell>
                    <TableCell align="right">{row.qtd_lotes}</TableCell>
                    <TableCell align="right">{row.qtd_ingressos}</TableCell>
                    <TableCell align="right">{row.qtd_app}</TableCell>
                    <TableCell align="right">{row.qtd_fisico}</TableCell>
                    <TableCell align="right">{row.qtd_site}</TableCell>
                    <TableCell align="right">
                      {moneyFormatter.format(row.valor_total)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell>Total</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell align="right">
                    {sector.ingressos.reduce(
                      (soma, el) => soma + el.qtd_ingressos,
                      0,
                    )}
                  </TableCell>
                  <TableCell align="right">
                    {sector.ingressos.reduce(
                      (soma, el) => soma + el.qtd_app,
                      0,
                    )}
                  </TableCell>
                  <TableCell align="right">
                    {sector.ingressos.reduce(
                      (soma, el) => soma + el.qtd_fisico,
                      0,
                    )}
                  </TableCell>
                  <TableCell align="right">
                    {sector.ingressos.reduce(
                      (soma, el) => soma + el.qtd_site,
                      0,
                    )}
                  </TableCell>
                  <TableCell align="right">
                    {moneyFormatter.format(
                      sector.ingressos.reduce(
                        (soma, el) => soma + el.valor_total,
                        0,
                      ),
                    )}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Grid>
      ))}
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>CUPONS</TableCell>
                <TableCell align="right">Tipo</TableCell>
                <TableCell align="right">Valor</TableCell>
                <TableCell align="right">Qtd. utilizado</TableCell>
                <TableCell align="right">Valor Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {salesResume.coupons.map((row) => (
                <TableRow
                  key={'row_' + row.codigo}
                  sx={{
                    '&:last-child td, &:last-child th': {
                      border: 0,
                    },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {row.codigo}
                  </TableCell>
                  <TableCell align="right">
                    {row.cod_tipo === 1 ? 'Fixo' : 'Percentual'}
                  </TableCell>
                  <TableCell align="right">
                    {row.cod_tipo === 1
                      ? moneyFormatter.format(row.valor)
                      : row.valor + '%'}
                  </TableCell>
                  <TableCell align="right">{row.quantidade}</TableCell>
                  <TableCell align="right">
                    {moneyFormatter.format(row.valor_total)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell>Total</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell align="right">
                  {salesResume.coupons.reduce(
                    (soma, el) => soma + Number(el.quantidade),
                    0,
                  )}
                </TableCell>
                <TableCell align="right">
                  {moneyFormatter.format(
                    salesResume.coupons.reduce(
                      (soma, el) => soma + Number(el.valor_total),
                      0,
                    ),
                  )}
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Grid>
      {salesResume.subEvents?.length > 0 && (
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <Typography variant="subtitle2">Sub eventos</Typography>
            {salesResume.subEvents.map((subEvent) => (
              <Button
                key={subEvent.id}
                variant="outlined"
                onClick={() => handleNavigateToSubEvent(subEvent.id)}
                sx={{ my: 1, mr: 2 }}
              >
                {subEvent.titulo}
              </Button>
            ))}
          </Grid>
        </Grid>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          {isLoading || !physicalSales ? (
            <Loading />
          ) : (
            <Container maxWidth="md">
              <TableContainer component={Paper} sx={{ margin: 'auto auto' }}>
                <Stack direction="row-reverse">
                  <IconButton onClick={handleClose}>
                    <Close />
                  </IconButton>
                </Stack>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Método pagamento</TableCell>
                      <TableCell align="right">Valor venda (R$)</TableCell>
                      <TableCell align="right">Taxa (%)</TableCell>
                      <TableCell align="right">Valor taxa (R$)</TableCell>
                      <TableCell align="right">Valor repasse (R$)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        Dinheiro
                      </TableCell>
                      <TableCell align="right">
                        {moneyFormatter.format(physicalSales.dinheiro)}
                      </TableCell>
                      <TableCell align="right">
                        {physicalSales.taxa_dinheiro}%
                      </TableCell>
                      <TableCell align="right">
                        {moneyFormatter.format(
                          physicalSales.valor_taxa_dinheiro,
                        )}
                      </TableCell>
                      <TableCell align="right">-</TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        Pix
                      </TableCell>
                      <TableCell align="right">
                        {moneyFormatter.format(physicalSales.pix)}
                      </TableCell>
                      <TableCell align="right">
                        {physicalSales.taxa_pix}%
                      </TableCell>
                      <TableCell align="right">
                        {moneyFormatter.format(physicalSales.valor_taxa_pix)}
                      </TableCell>
                      <TableCell align="right">
                        {moneyFormatter.format(
                          physicalSales.pix - physicalSales.valor_taxa_pix,
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        Crédito
                      </TableCell>
                      <TableCell align="right">
                        {moneyFormatter.format(physicalSales.credito)}
                      </TableCell>
                      <TableCell align="right">
                        {physicalSales.taxa_credito}%
                      </TableCell>
                      <TableCell align="right">
                        {moneyFormatter.format(
                          physicalSales.valor_taxa_credito,
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {moneyFormatter.format(
                          physicalSales.credito -
                            physicalSales.valor_taxa_credito,
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        Débito
                      </TableCell>
                      <TableCell align="right">
                        {moneyFormatter.format(physicalSales.debito)}
                      </TableCell>
                      <TableCell align="right">
                        {physicalSales.taxa_debito}%
                      </TableCell>
                      <TableCell align="right">
                        {moneyFormatter.format(physicalSales.valor_taxa_debito)}
                      </TableCell>
                      <TableCell align="right">
                        {moneyFormatter.format(
                          physicalSales.debito -
                            physicalSales.valor_taxa_debito,
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        Total
                      </TableCell>
                      <TableCell align="right">
                        {moneyFormatter.format(
                          physicalSales.valor_total_vendas,
                        )}
                      </TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right">
                        {moneyFormatter.format(physicalSales.valor_total_taxas)}
                      </TableCell>
                      <TableCell align="right">
                        {moneyFormatter.format(
                          Number(physicalSales.pix) +
                            Number(physicalSales.debito) +
                            Number(physicalSales.credito) -
                            Number(physicalSales.valor_total_taxas),
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Container>
          )}
        </>
      </Modal>
    </Grid>
  )
}

export default SalesResume
