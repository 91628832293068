import { Container, Paper } from '@mui/material'
import React from 'react'

const PrivacyPolicyPage: React.FC = () => {
  return (
    <Container sx={{ padding: 2, marginY: 2 }} component={Paper}>
      <h1>Política de Privacidade - Zé do Ingresso</h1>

      <p>
        A sua privacidade é importante para nós. Esta política explica como
        coletamos, usamos, armazenamos e protegemos suas informações pessoais ao
        utilizar a plataforma Zé do Ingresso.
      </p>

      <p>
        Se tiver dúvidas, entre em contato pelo e-mail:{' '}
        <a href="mailto:contato@zedoingresso.com.br">
          contato@zedoingresso.com.br
        </a>{' '}
        ou pelos canais oficiais.
      </p>

      <h2>1. Informações Coletadas</h2>

      <h3>a) Para Organizadores de Eventos</h3>
      <ul>
        <li>Nome completo;</li>
        <li>E-mail e telefone de contato;</li>
        <li>Endereço;</li>
        <li>Dados bancários (para repasse de valores das vendas).</li>
      </ul>
      <p>
        Esses dados são utilizados exclusivamente para a operacionalização e
        pagamento de vendas.
      </p>

      <h3>b) Para Compradores de Ingressos</h3>
      <ul>
        <li>Nome completo;</li>
        <li>E-mail e telefone de contato;</li>
        <li>
          Eventuais dados adicionais solicitados pelo organizador (ex.: CPF,
          endereço, escolaridade).
        </li>
      </ul>
      <p>
        Os dados de pagamento (cartão de crédito, boleto ou PIX) são processados
        por nossos parceiros (PagSeguro) e não são armazenados em nossos
        servidores.
      </p>

      <h3>c) Informações de Navegação</h3>
      <p>Podemos coletar automaticamente informações como:</p>
      <ul>
        <li>Endereço IP;</li>
        <li>Tipo de dispositivo e navegador;</li>
        <li>Páginas acessadas e tempo de navegação;</li>
        <li>Cookies para melhorar sua experiência e autenticar seu acesso.</li>
      </ul>
      <p>
        Você pode gerenciar suas preferências de cookies no seu navegador, mas
        algumas funcionalidades podem ser afetadas.
      </p>

      <h2>2. Como Utilizamos suas Informações</h2>
      <p>Seus dados pessoais são utilizados para:</p>
      <ul>
        <li>Viabilizar a compra, venda e entrega de ingressos;</li>
        <li>Processar pagamentos e realizar o repasse aos organizadores;</li>
        <li>
          Enviar confirmações, atualizações e comunicações relacionadas ao
          evento;
        </li>
        <li>Melhorar a experiência do usuário e personalizar serviços;</li>
        <li>Cumprir obrigações legais e garantir a segurança da plataforma.</li>
      </ul>

      <h2>3. Compartilhamento de Informações</h2>
      <p>
        O Zé do Ingresso não vende nem compartilha seus dados pessoais com
        terceiros, exceto:
      </p>
      <ul>
        <li>
          <strong>Com organizadores de eventos:</strong> Ao comprar um ingresso,
          suas informações são repassadas ao organizador para viabilizar o
          evento. O organizador é responsável pelo uso dessas informações
          conforme suas próprias políticas de privacidade.
        </li>
        <li>
          <strong>Com parceiros de pagamento:</strong> Dados financeiros são
          processados pelo PagSeguro com total segurança.
        </li>
        <li>
          <strong>Por exigência legal:</strong> Podemos divulgar suas
          informações para cumprir obrigações legais ou proteger nossos
          direitos.
        </li>
      </ul>

      <h2>4. Segurança dos Dados</h2>
      <p>
        Adotamos medidas técnicas e organizacionais para proteger suas
        informações:
      </p>
      <ul>
        <li>
          Utilização de criptografia SSL (Secure Socket Layer) em todas as
          transações;
        </li>
        <li>Senhas armazenadas de forma criptografada;</li>
        <li>Monitoramento contínuo para prevenir acessos não autorizados.</li>
      </ul>

      <h2>5. Armazenamento e Exclusão de Dados</h2>
      <p>
        Seus dados pessoais são armazenados pelo tempo necessário para cumprir
        as finalidades desta Política e obrigações legais. Você pode solicitar a
        exclusão dos seus dados pelo e-mail:{' '}
        <a href="mailto:contato@zedoingresso.com.br">
          contato@zedoingresso.com.br
        </a>
        .
      </p>

      <h2>6. Seus Direitos</h2>
      <p>Você tem o direito de:</p>
      <ul>
        <li>Acessar, corrigir ou excluir seus dados pessoais;</li>
        <li>Revogar seu consentimento a qualquer momento;</li>
        <li>Solicitar informações sobre como seus dados são tratados.</li>
      </ul>
      <p>
        Para exercer esses direitos, entre em contato pelo e-mail informado
        acima.
      </p>

      <h2>7. Alterações nesta Política</h2>
      <p>
        Esta Política pode ser atualizada a qualquer momento para refletir
        mudanças na plataforma ou em requisitos legais. A versão mais recente
        estará sempre disponível em nosso site.
      </p>

      <p>
        Ao continuar usando a plataforma, você concorda com os termos desta
        Política de Privacidade.
      </p>

      <p>
        <strong>Zé do Ingresso - Conectando Você aos Melhores Eventos!</strong>
      </p>

      <p>
        <em>Ultima atualização: 30 de maio de 2022.</em>
      </p>
    </Container>
  )
}

export default PrivacyPolicyPage
