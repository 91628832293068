import { useState, useEffect } from 'react'
import { useController, Control } from 'react-hook-form'
import { Box, Button, Typography } from '@mui/material'
import { constants } from 'config'

interface ImageUploadFieldProps {
  name: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>
  label: string
  width?: number
  height?: number
  objectFit?: string
}

export const ImageUploadField: React.FC<ImageUploadFieldProps> = ({
  name,
  control,
  label,
  width = 100,
  height = 100,
  objectFit = 'cover',
}) => {
  const {
    formState: { isDirty },
    field: { onChange, value },
    fieldState: { error },
  } = useController({ name, control })

  const [preview, setPreview] = useState<string | null>(
    typeof value === 'string' ? value : null,
  )

  useEffect(() => {
    if (typeof value === 'string') {
      setPreview(value)
    }
  }, [value])

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      const objectUrl = URL.createObjectURL(file)
      setPreview(objectUrl)
      onChange(file)
    }
  }

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Typography>{label}</Typography>
      {preview && (
        <Box
          component="img"
          src={(isDirty ? '' : constants.URL_FTP) + preview}
          alt="Preview"
          sx={{
            width,
            height,
            objectFit,
            borderRadius: 2,
            filter: 'grayscale(100%)',
            borderWidth: 1,
            borderStyle: 'dashed',
          }}
        />
      )}
      <Button variant="contained" component="label">
        Selecionar Imagem
        <input
          type="file"
          hidden
          accept="image/*"
          onChange={handleFileChange}
        />
      </Button>
      {error && <Typography color="error">{error.message}</Typography>}
    </Box>
  )
}
