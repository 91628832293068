import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { Alert, AlertTitle, Box } from '@mui/material'
import { FC } from 'react'

interface ErrorProps {
  error: Error
}

const ErrorComponent: FC<ErrorProps> = ({ error }) => {
  console.error(error)

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Alert severity="error" icon={<ErrorOutlineIcon fontSize="inherit" />}>
        <AlertTitle>Erro</AlertTitle>
        {error.message}
      </Alert>
    </Box>
  )
}

export default ErrorComponent
