import clientAdmin from 'services/api/v1/admin/client'

export type Order = {
  id: string
  // status: string;
  // hash: string;
  // totalAmount: number;
  // discountCouponValue: number;
  // processingFeeAmount: number;
  // createdAt: Date;
  //   event?: {
  //     id: string;
  //     name: string;
  //     slug: string;
  //     image: string;
  //     startDateTime: string;
  //     endDateTime: string;
  //     location: string;
  //     city: string;
  //     uf: string;
  // }
  user?: {
    id: string
    email: string
    cpf: string
    //   createdAt: Date;
    //   firstName: string;
    //   lastName: string;
    fullName: string
    phone: string
    //   birthDate: string;
    //   gender: string;
    // cityId: string
    //   isEmailVerified: boolean;
    //   isPhoneVerified: boolean;
  }
}

export async function fetchSuspectedOrders() {
  const response = await clientAdmin.get<{ orders: Order[] }>(
    `/orders/suspected`,
  )
  return response.data
}
