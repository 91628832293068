import { useMutation, useQueryClient } from '@tanstack/react-query'

interface MutateOptions<T, TData> {
  onSuccess?: (data: T) => void
  onError?: (error: Error) => void
  onSettled?: () => void
  invalidateKeys?: string[] // Chaves de cache para invalidar após sucesso
  optimisticUpdate?: {
    key: string
    updater: (oldData: unknown, newData: TData) => unknown
  } // Para UI mais rápida
}

type MutationFunction<T, TData> = (data: TData) => Promise<T>

/**
 * Define o contexto de mutação para armazenar `previousData`
 */
interface MutationContext<TData> {
  previousData?: TData
}

/**
 * Hook para realizar mutações (POST, PUT, DELETE) com React Query e cache otimizado.
 */
export function useMutateData<T, TData extends object>(
  mutationFn: MutationFunction<T, TData>,
  options?: MutateOptions<T, TData>,
) {
  const queryClient = useQueryClient()

  return useMutation<T, Error, TData, MutationContext<TData>>({
    mutationFn: async (data) => {
      const response = await mutationFn(data)
      return response
    },
    onMutate: async (newData) => {
      if (options?.optimisticUpdate) {
        await queryClient.cancelQueries({
          queryKey: [options.optimisticUpdate.key] as const,
        })

        const previousData = queryClient.getQueryData<TData>([
          options.optimisticUpdate.key,
        ] as const)
        queryClient.setQueryData(
          [options.optimisticUpdate.key] as const,
          (old) => options.optimisticUpdate?.updater(old, newData),
        )

        return { previousData }
      }

      return {} // Retorna um objeto vazio para evitar erro de tipagem
    },
    onSuccess: (data) => {
      if (options?.invalidateKeys) {
        options.invalidateKeys.forEach((key) =>
          queryClient.invalidateQueries({ queryKey: [key] as const }),
        )
      }
      options?.onSuccess?.(data)
    },
    onError: (error, _, context) => {
      if (options?.optimisticUpdate && context?.previousData) {
        queryClient.setQueryData(
          [options.optimisticUpdate.key] as const,
          context.previousData,
        )
      }
      options?.onError?.(error)
    },
    onSettled: () => {
      options?.onSettled?.()
    },
  })
}
