import clientProducer from './clientProducer'

const eventImage = (data: FormData) => {
  return clientProducer.post<string>(`upload/event`, data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

const batchImage = (data: FormData) => {
  return clientProducer.post(`upload/batches`, data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

const promoterImage = (data: FormData) => {
  return clientProducer.post<{ path: string }>(`upload/promoter-image`, data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

const promoterFolder = (data: FormData) => {
  return clientProducer.post<{ path: string }>(`upload/promoter-folder`, data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

const uploadService = {
  eventImage,
  batchImage,
  promoterImage,
  promoterFolder,
}

export default uploadService
