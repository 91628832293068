import { zodResolver } from '@hookform/resolvers/zod'
import { CalendarMonth } from '@mui/icons-material'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  List,
  ListItem,
  ListItemText,
  Paper,
} from '@mui/material'
import {
  Desktop,
  Folders,
  ListNumbers,
  Money,
  MoneyWavy,
  Ticket,
  UsersThree,
} from '@phosphor-icons/react'
import { Button } from 'components/button'
import { MultiSelectCheckmark } from 'components/multi-select-checkmark'
import { useGeneralReport } from 'hooks/use-general-report'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { moneyFormatter } from 'utils/formatter'
import { z } from 'zod'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

const schema = z.object({
  users: z.array(z.string()),
  channels: z.array(z.string()),
  paymentMethods: z.array(z.string()),
  dates: z.array(z.string()),
  sectors: z.array(z.string()),
  batches: z.array(z.string()),
})

export type GeneralEventReportType = z.infer<typeof schema>

export function GeneralReport() {
  const eventId = useParams<{ eventId: string }>().eventId as string

  const {
    params: {
      channelOptions,
      datesOptions,
      paymentMethodOptions,
      userOptions,
      batchOptions,
      sectorOptions,
    },
    isLoading,
    generateReport,
    generalReport,
  } = useGeneralReport(eventId)

  const { control, handleSubmit } = useForm<GeneralEventReportType>({
    resolver: zodResolver(schema),
    defaultValues: {
      users: [],
      channels: [],
      paymentMethods: [],
      dates: [],
      sectors: [],
      batches: [],
    },
  })

  const onSubmit = async (data: GeneralEventReportType) => {
    await generateReport(data)
  }

  return (
    <Container
      maxWidth="xl"
      sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card component={Paper}>
          <CardHeader
            avatar={<CalendarMonth />}
            title="Relatórios"
            subheader="Veja informações gerais do seu evento"
          />
          <CardContent
            sx={{
              display: 'grid',
              gridTemplateColumns: {
                xs: '1fr',
                sm: 'repeat(2, 1fr)',
                md: 'repeat(3, 1fr)',
              },
              gap: 2,
            }}
          >
            <MultiSelectCheckmark
              name="users"
              control={control}
              options={userOptions}
              label="Usuários"
            />
            <MultiSelectCheckmark
              name="sectors"
              control={control}
              options={sectorOptions}
              label="Setor"
            />
            <MultiSelectCheckmark
              name="channels"
              control={control}
              options={channelOptions}
              label="Canais de Venda"
            />
            <MultiSelectCheckmark
              name="paymentMethods"
              control={control}
              options={paymentMethodOptions}
              label="Forma de Pagamento"
            />
            <MultiSelectCheckmark
              name="batches"
              control={control}
              options={batchOptions}
              label="Lote"
            />
            <MultiSelectCheckmark
              name="dates"
              control={control}
              options={datesOptions}
              label="Data"
            />

            <Button
              variant="contained"
              type="submit"
              fullWidth
              isLoading={isLoading}
            >
              Gerar Relatório
            </Button>
          </CardContent>
        </Card>
      </form>

      {generalReport && (
        <Box sx={{ display: 'grid', gap: 2 }}>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: {
                xs: '1fr',
                sm: 'repeat(2, 1fr)',
              },
              gap: 2,
            }}
          >
            <Card>
              <CardHeader
                avatar={<Ticket />}
                title="Ingressos aprovados"
                subheader={generalReport.report.quantityTicketsApproved}
              />
            </Card>

            <Card>
              <CardHeader
                avatar={<Money />}
                title="Total de vendas"
                subheader={moneyFormatter.format(
                  generalReport.report.totalSales,
                )}
              />
            </Card>
          </Box>

          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: {
                xs: '1fr',
                sm: 'repeat(2, 1fr)',
              },
              gap: 2,
            }}
          >
            <Card>
              <CardHeader avatar={<Desktop />} title="Canais de Venda" />

              <List dense>
                {generalReport.report.salesChannel.map((channel) => (
                  <ListItem key={channel.name}>
                    <ListItemText
                      sx={{
                        display: 'flex',
                        gap: 2,
                        justifyContent: 'space-between',
                      }}
                      primary={channel.name}
                      secondary={moneyFormatter.format(channel.total)}
                    />
                  </ListItem>
                ))}
              </List>
            </Card>

            <Card>
              <CardHeader avatar={<MoneyWavy />} title="Formas de Pagamento" />
              <List dense>
                {generalReport.report.paymentMethods.map((paymentMethod) => (
                  <ListItem key={paymentMethod.name}>
                    <ListItemText
                      sx={{
                        display: 'flex',
                        gap: 2,
                        justifyContent: 'space-between',
                      }}
                      primary={paymentMethod.name}
                      secondary={moneyFormatter.format(paymentMethod.total)}
                    />
                  </ListItem>
                ))}
              </List>
            </Card>
          </Box>

          <Card>
            <CardHeader avatar={<Folders />} title="Setores" />

            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Setor</TableCell>
                    <TableCell align="right">Quantidade</TableCell>
                    <TableCell align="right">Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {generalReport.report.sectors.map((sector, index) => (
                    <TableRow
                      key={sector.name + index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {sector.name}
                      </TableCell>
                      <TableCell align="right">{sector.quantity}</TableCell>
                      <TableCell align="right">
                        {moneyFormatter.format(sector.total)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>

          <Card>
            <CardHeader avatar={<ListNumbers />} title="Lotes" />
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Lote</TableCell>
                  <TableCell align="right">Valor</TableCell>
                  <TableCell align="right">Quantidade</TableCell>
                  <TableCell align="right">Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {generalReport.report.batches.map((batch, index) => (
                  <TableRow
                    key={batch.name + index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {batch.name}
                    </TableCell>
                    <TableCell align="right">
                      {moneyFormatter.format(batch.value)}
                    </TableCell>
                    <TableCell align="right">{batch.quantity}</TableCell>
                    <TableCell align="right">
                      {moneyFormatter.format(batch.total)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Card>

          <Card>
            <CardHeader avatar={<UsersThree />} title="Equipe" />
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Nome</TableCell>
                  <TableCell align="right">Quantidade</TableCell>
                  <TableCell align="right">Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {generalReport.report.staff.map((staff, index) => (
                  <TableRow
                    key={staff.id + index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {staff.name}
                    </TableCell>
                    <TableCell align="right">{staff.quantity}</TableCell>
                    <TableCell align="right">
                      {moneyFormatter.format(staff.total)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Card>
        </Box>
      )}
    </Container>
  )
}
