import { NoMatch } from 'components'
import ShoppingCartProvider from 'contexts/shoppingCartContext'
import UserProvider from 'contexts/userContext'
import { UserLayout } from 'layout'
import CheckoutPage from 'pages/user/CheckoutPage'
import CreateProducer from 'pages/user/CreateProducer'
import { DownloadApp } from 'pages/user/DownloadApp'
import { OrderBoleto } from 'pages/user/OrderBoleto'
import { PaymentProcessPage } from 'pages/user/PaymentProcessPage'
import PaymentWithPix from 'pages/user/PaymentWithPix'
import ProducerEvents from 'pages/user/ProducerEvents'
import React from 'react'
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom'

import { NewCheckoutPage } from 'pages/user/checkout/checkout'
import {
  CheckEmailPage,
  CheckPhoneNumberPage,
  CompleteRegistrationPage,
  CreateOrderPage,
  DoubsPage,
  EmailConfirmation,
  EventPage,
  FinalizeRegistration,
  ForgetPasswordPage,
  HomePage,
  OrderDetailsPage,
  PaymentGatewayReturn,
  PrivacyPolicyPage,
  RecoverPasswordPage,
  SignInPage,
  SignUpPage,
  UserDetails,
  UserOrdersPage,
  UseTermsPage,
} from '../pages/user'
import { itsCompleteRegistration } from 'utils/userUtils'

const ProtectedRoute = () => {
  const location = useLocation()
  const user = localStorage.getItem('user')
  const token = localStorage.getItem('user_token')

  if (!token || !user) {
    return <Navigate to="/login" replace state={{ from: location }} />
  }

  if (location.pathname !== '/usuario/completar-cadastro') {
    const userParsed = JSON.parse(user)
    if (!itsCompleteRegistration(userParsed)) {
      return (
        <Navigate
          to="/usuario/completar-cadastro"
          replace
          state={{ from: location }}
        />
      )
    }
  }

  return <Outlet />
}

const UserRouter: React.FC = () => {
  return (
    <UserProvider>
      <ShoppingCartProvider>
        <Routes>
          <Route
            path="/"
            element={
              <UserLayout>
                <Outlet />
              </UserLayout>
            }
          >
            <Route index element={<HomePage />} />
            <Route
              path="bartolomeu-jk"
              element={<ProducerEvents producerId={6} />}
            />
            <Route
              path="mixedclub"
              element={<ProducerEvents producerId={69} />}
            />
            <Route
              path="triadeentreterimento"
              element={<ProducerEvents producerId={138} />}
            />
            <Route
              path="ranchodocabeca"
              element={<ProducerEvents producerId={231} />}
            />
            <Route path="lush" element={<ProducerEvents producerId={559} />} />
            <Route
              path="jojoproducoes"
              element={<ProducerEvents producerId={256} />}
            />
            <Route
              path="startproducoes"
              element={<ProducerEvents producerId={1} />}
            />
            <Route path="cadastro" element={<SignUpPage />} />
            <Route path="signup" element={<SignUpPage />} />
            <Route path="duvidas" element={<DoubsPage />} />
            <Route path="termos-de-uso" element={<UseTermsPage />} />
            <Route path="app" element={<DownloadApp />} />
            <Route
              path="politica-de-privacidade"
              element={<PrivacyPolicyPage />}
            />
            <Route
              path="finalizar-cadastro"
              element={<FinalizeRegistration />}
            />
            <Route
              path="confirmar-email/:userId/:token"
              element={<EmailConfirmation />}
            />
            <Route
              path="evento/:eventIdParams/:idURL"
              element={<EventPage />}
            />

            <Route path="/" element={<ProtectedRoute />}>
              <Route path="checkout" element={<NewCheckoutPage />} />

              <Route
                path="evento/:eventId/checkout"
                element={<CheckoutPage />}
              />

              <Route
                path="cadastre-sua-produtora"
                element={<CreateProducer />}
              />
              <Route
                path="evento/:eventId/:idURL/novo-pedido"
                element={<CreateOrderPage />}
              />

              <Route
                path="pagamento-com-pix/:orderId"
                element={<PaymentWithPix />}
              />
              <Route
                path="processar-pagamento/:orderId"
                element={<PaymentProcessPage />}
              />
              <Route path="visualizar-boleto" element={<OrderBoleto />} />
              <Route path="usuario">
                <Route index element={<UserDetails />} />
                <Route
                  path="completar-cadastro"
                  element={<CompleteRegistrationPage />}
                />
                <Route path="verificar-email" element={<CheckEmailPage />} />
                <Route
                  path="verificar-numero-telefone"
                  element={<CheckPhoneNumberPage />}
                />
                <Route path="pedidos" element={<UserOrdersPage />} />
                <Route path="pedidos/:orderId" element={<OrderDetailsPage />} />
              </Route>
              <Route
                path="payment-gateway-return/:paymentGateway/:status"
                element={<PaymentGatewayReturn />}
              />
            </Route>
            <Route path="login" element={<SignInPage />} />
            <Route path="esqueci-senha" element={<ForgetPasswordPage />} />
            <Route
              path="usuario/:id/recuperar-senha/:hash"
              element={<RecoverPasswordPage />}
            />
            <Route path="*" element={<NoMatch />} />
          </Route>
        </Routes>
      </ShoppingCartProvider>
    </UserProvider>
  )
}

export default UserRouter
