import { zodResolver } from '@hookform/resolvers/zod'
import { Autocomplete, Button, Grid, Paper, TextField } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { DateTimePicker } from '@mui/x-date-pickers'
import { Typography } from 'components/Typography'
import { useAdmin } from 'contexts/adminContext'
import dayjs from 'dayjs'
import { useFetchData } from 'hooks/use-fetch-data'
import { useMutateData } from 'hooks/use-mutate-data'
import { fetchOrganizations } from 'http/admin/fetch-organizations'
import {
  fetchSuspectedUsers,
  type SuspectedUser,
} from 'http/admin/fetch-suspected-users'
import { Controller, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { datagrid } from 'utils'
import { getErrorMessage } from 'utils/AppError'
import { z } from 'zod'

const suspectedUsersFormSchema = z.object({
  initialDate: z.date(),
  finalDate: z.date(),
  cod_promoter: z
    .array(z.object({ id: z.number(), label: z.string() }))
    .transform((val) => val.map((x) => x.id))
    .optional(),
})

type SuspectedUsersFormInput = z.input<typeof suspectedUsersFormSchema>

export default function SuspectedUsers() {
  const { addErrorMessage } = useAdmin()

  const organizationsResponse = useFetchData(fetchOrganizations)

  const suspiciousUsersResponse = useMutateData(fetchSuspectedUsers, {
    onError(error) {
      addErrorMessage(getErrorMessage(error))
    },
  })

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<SuspectedUsersFormInput>({
    resolver: zodResolver(suspectedUsersFormSchema),
    defaultValues: {
      initialDate: dayjs().startOf('year').toDate(),
      finalDate: dayjs().endOf('seconds').toDate(),
    },
  })

  const columns: GridColDef<SuspectedUser>[] = [
    {
      field: 'userId',
      headerName: '#',
      width: 100,
      renderCell: ({ value }) => (
        <Link to={`/admin/usuarios/${value}`} target="_blank">
          {value}
        </Link>
      ),
    },
    { field: 'name', headerName: 'Usuário', flex: 1 },
    {
      field: 'totalAlerts',
      headerName: 'Quantidade de alertas',
      width: 200,
    },
    {
      field: 'sharedCreditCard',
      headerName: 'Cartão compartilhado',
      width: 200,
    },
    {
      field: 'lastOrderDate',
      headerName: 'Última transação',
      width: 200,
      renderCell: ({ value }) => dayjs(value).format('DD/MM/YYYY HH:mm'),
    },
  ]

  function fetchData(data: SuspectedUsersFormInput) {
    suspiciousUsersResponse.mutate(data)
  }

  return (
    <Grid container spacing={2} component={Paper} p={2}>
      <Grid item xs={12}>
        <Typography variant="h4">
          Usuários com comportamento suspeito
        </Typography>
      </Grid>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={4}>
          <Controller
            control={control}
            name="initialDate"
            rules={{ required: true }}
            render={({ field }) => (
              <DateTimePicker
                label="Início do período"
                value={dayjs(field.value).toDate()}
                inputRef={field.ref}
                onChange={(date) => {
                  field.onChange(date)
                }}
                slotProps={{
                  textField: {
                    error: !!errors.initialDate,
                    helperText: errors.initialDate?.message,
                    fullWidth: true,
                  },
                }}
                disablePast
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            control={control}
            name="finalDate"
            rules={{ required: true }}
            render={({ field }) => (
              <DateTimePicker
                label="Fim do período"
                value={dayjs(field.value).toDate()}
                inputRef={field.ref}
                onChange={(date) => {
                  field.onChange(date)
                }}
                slotProps={{
                  textField: {
                    error: !!errors.finalDate,
                    helperText: errors.finalDate?.message,
                    fullWidth: true,
                  },
                }}
                disablePast
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            control={control}
            name="cod_promoter"
            render={({ field, formState: { errors } }) => (
              <Autocomplete
                disablePortal
                multiple
                options={
                  organizationsResponse.data?.organizations.map((org) => ({
                    id: Number(org.organizationId),
                    label: org.name,
                  })) || []
                }
                getOptionLabel={(option) => option.label}
                id="producers-autocomplete"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    key={params.id}
                    label="Produtora"
                    fullWidth
                    error={!!errors.cod_promoter}
                    helperText={errors.cod_promoter?.message}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option.id}>
                    {option.label}
                  </li>
                )}
                {...field}
                onChange={(event, value) => field.onChange(value)}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={handleSubmit(fetchData)}
            disabled={isSubmitting}
          >
            Carregar dados
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          rows={suspiciousUsersResponse.data?.report || []}
          columns={columns}
          autoHeight
          localeText={datagrid.localizedTextsMap}
          getRowId={(row) => row.userId}
        />
      </Grid>
    </Grid>
  )
}
