import { Alert, Snackbar, Toolbar } from '@mui/material'
import { AxiosError } from 'axios'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { apiV1 } from 'services'
import { IEvento } from 'types/evento'
import { PromoterContextType } from 'types/promoter'
import { IUsuario } from 'types/usuario'
import { functionWithLoading } from 'utils'

export const PromoterContext = React.createContext<PromoterContextType | null>(
  null,
)

export const LogoutPromoter = () => {
  localStorage.removeItem('promoter')
  localStorage.removeItem('promoter_token')
  localStorage.removeItem('token')
  window.location.reload()
}

const PromoterProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [promoter, setPromoter] = React.useState<IUsuario>({} as IUsuario)
  const [selectedEvent, setSelectedEvent] = React.useState<IEvento>()
  const [loading, setLoading] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [errors, setErrors] = React.useState<string[]>([])
  const [pageTitle, setPageTitle] = React.useState('Painel do organizador')

  const navigate = useNavigate()

  React.useEffect(() => {
    const sessionPromoter = localStorage.getItem('promoter')
    const sessionSelectedEvent = localStorage.getItem('selectedEvent')
    if (sessionPromoter) {
      setPromoter(JSON.parse(sessionPromoter))
    }
    if (sessionSelectedEvent) {
      setSelectedEvent(JSON.parse(sessionSelectedEvent))
    }
  }, [])

  const login = (username: string, password: string) => {
    functionWithLoading(
      apiV1.promoterService
        .login(username, password)
        .then((res) => {
          if (res.data.auth) {
            localStorage.setItem('promoter', JSON.stringify(res.data.usuario))
            localStorage.setItem('promoter_token', res.data.token)
            localStorage.setItem('token', res.data.token)
            setPromoter(res.data.usuario)
            setErrorMessage('')
          } else {
            setErrorMessage('Usuário ou senha incorretos!')
          }
        })
        .catch((error: AxiosError) =>
          setErrorMessage(error.response?.data.message ?? error.message),
        ),
      setLoading,
    )
  }

  const logout = React.useCallback(() => {
    setPromoter({} as IUsuario)
    localStorage.removeItem('promoter')
    localStorage.removeItem('promoter_token')
    navigate('/organizador/login')
  }, [navigate])

  const addErrorMessage = React.useCallback((message: string) => {
    setErrors((prevState) => [...prevState, message])
  }, [])

  const closeError = React.useCallback((index) => {
    setErrors((prevState) => prevState.filter((_, i) => i !== index))
  }, [])

  return (
    <PromoterContext.Provider
      value={{
        promoter,
        login,
        logout,
        loading,
        errorMessage,
        pageTitle,
        setPageTitle,
        selectedEvent,
        setSelectedEvent,
        addErrorMessage,
        errors,
        closeError,
      }}
    >
      <Toolbar />
      {children}
      {errors.map((error, index) => (
        <Snackbar
          key={`error_` + index}
          open={!!error}
          autoHideDuration={6000}
          onClose={() => closeError(index)}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        >
          <Alert
            onClose={() => closeError(index)}
            severity="error"
            sx={{ width: '100%' }}
          >
            {error}
          </Alert>
        </Snackbar>
      ))}
    </PromoterContext.Provider>
  )
}

const ERROR_MSG_PROVIDER_NEEDED =
  'usePromoter must be used within a PromoterProvider'

export const usePromoter = () => {
  const context = React.useContext(PromoterContext)
  if (!context) {
    throw new Error(ERROR_MSG_PROVIDER_NEEDED)
  }
  return context
}

export default PromoterProvider
