/* eslint-disable camelcase */
import { zodResolver } from '@hookform/resolvers/zod'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import { LoadingButton } from '@mui/lab'
import { Alert, Grid, Paper, Skeleton, Stack, TextField } from '@mui/material'
import { ImageUploadField } from 'components/image-upload-file'
import { Typography } from 'components/Typography'
import { useFormProducer } from 'hooks/use-form-producer'
import { useCallback, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import ReactInputMask from 'react-input-mask'
import { useParams } from 'react-router-dom'
import { apiV1 } from 'services'
import { z } from 'zod'

const producerSchema = z.object({
  razao_social: z.string({ required_error: 'Informe o nome da produtora' }),
  email: z
    .string({ required_error: 'Informe o nome da produtora' })
    .email('Informe um e-mail válido'),
  telefone: z.string({
    required_error: 'Informe o telefone para contato da produtora',
  }),
  cnpj: z.string().optional(),
  capa: z
    .any()
    // .refine((file) => file instanceof File || typeof file === 'string', {
    //   message: 'Uma capa é obrigatória',
    // })
    .optional(),
  imagem: z
    .any()
    // .refine((file) => file instanceof File || typeof file === 'string', {
    //   message: 'Uma logo é obrigatória',
    // })
    .optional(),
})

type ProducerData = z.infer<typeof producerSchema>

export function Producer() {
  const producerId = useParams().producerId as string
  const { producer, isLoading, saveProducer } = useFormProducer(producerId)

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty },
    reset,
  } = useForm<ProducerData>({
    resolver: zodResolver(producerSchema),
    defaultValues: {
      razao_social: '',
      email: '',
      telefone: '',
      cnpj: '',
      capa: '',
      imagem: '',
    },
  })

  useEffect(() => {
    if (producer) {
      reset(producer)
    }
  }, [producer, reset])

  const onSubmit = useCallback(
    async ({
      razao_social,
      email,
      telefone,
      cnpj,
      imagem,
      capa,
    }: ProducerData) => {
      if (isSubmitting) return

      if (imagem instanceof File) {
        const formData = new FormData()
        formData.append('imagem', imagem)
        const uploaded =
          await apiV1.producer.uploadService.promoterImage(formData)
        imagem = uploaded.data.path
      }

      if (capa instanceof File) {
        const formData = new FormData()
        formData.append('imagem', capa)
        const uploaded =
          await apiV1.producer.uploadService.promoterFolder(formData)
        capa = uploaded.data.path
      }

      await saveProducer({
        razao_social,
        email,
        telefone,
        cnpj,
        capa,
        imagem,
      })
    },
    [isSubmitting, saveProducer],
  )

  return (
    <Grid container spacing={2} component={Paper} py={2} pr={2} mt={6}>
      <Grid item xs={12}>
        <Typography variant="h6">Informações da produtora</Typography>
      </Grid>
      {isLoading ? (
        <Grid item xs={12}>
          <PageLoading />
        </Grid>
      ) : (
        <>
          <Grid item xs={12}>
            <Alert severity="info">
              Essas informações serão exibidas na página do evento.
            </Alert>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Nome da produtora"
              error={!!errors.razao_social}
              helperText={errors.razao_social?.message}
              fullWidth
              required
              {...register('razao_social')}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="E-mail"
              error={!!errors.email}
              helperText={errors.email?.message}
              fullWidth
              required
              {...register('email')}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="telefone"
              render={({ field }) => (
                <ReactInputMask mask="(99) 99999-9999" {...field}>
                  <TextField
                    label="Telefone para contato"
                    error={!!errors.telefone}
                    helperText={errors.telefone?.message}
                  />
                </ReactInputMask>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="CNPJ se existir"
              error={!!errors.cnpj}
              helperText={errors.cnpj?.message}
              fullWidth
              {...register('cnpj')}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <ImageUploadField name="capa" control={control} label="Capa" />
          </Grid>
          <Grid item xs={12} sm={3}>
            <ImageUploadField name="imagem" control={control} label="Logo" />
          </Grid>

          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-end">
              <LoadingButton
                loading={isLoading}
                loadingPosition="start"
                startIcon={<SaveOutlinedIcon />}
                variant="outlined"
                onClick={handleSubmit(onSubmit)}
                disabled={!isDirty}
              >
                Atualizar
              </LoadingButton>
            </Stack>
          </Grid>
        </>
      )}
    </Grid>
  )
}

function PageLoading() {
  return (
    <Grid item xs={12}>
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </Grid>
  )
}
