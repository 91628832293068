import clientAdmin from 'services/api/v1/admin/client'
import type { IPromoter } from 'types/promoter'

export type Organizations = {
  organizationId: string
  name: string
}

export async function fetchOrganizations() {
  const response = await clientAdmin.get<{ promoters: IPromoter[] }>(
    `promoters`,
  )

  return {
    organizations: response.data.promoters.map((organization: IPromoter) => ({
      organizationId: organization.id,
      name: organization.razao_social,
    })),
  }
}
